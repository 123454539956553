import { environment } from '#environments/environment';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FunctionalReviewResponseItem } from '../../models/functional-review-response-item';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ResponseItemService {
  deleteResponseItem(actionItemId: number): Observable<void> {
    return this.http.delete<void>(`${environment.apiEndpoint}/fr/response-items/${actionItemId}`);
  }

  addResponseItem(responseItem: FunctionalReviewResponseItem) {
    return this.http.post<FunctionalReviewResponseItem>(`${environment.apiEndpoint}/fr/response-items`, responseItem);
  }

  editResponseItem(responseItem: FunctionalReviewResponseItem) {
    return this.http.put<FunctionalReviewResponseItem>(`${environment.apiEndpoint}/fr/response-items/${responseItem.ID}`, responseItem);
  }

  constructor(private http: HttpClient) { }



}
