<h2>{{'FR.Titles.ManageChecklist'|translate:taskPlan}}</h2>
<div class="row">
  <div class="col-md-4">
    <div class="panel panel-default">
      <div class="panel-heading">
        <h3 class="taskplanssections__header panel-title">
          {{'FR.Titles.ChecklistSections'|translate}}
        </h3>
      </div>
      <div class="panel-body taskplanssections__container">
        <ul *ngIf="taskPlan?.TaskPlanSections?.length &gt; 0" class="nav nav-pills nav-stacked">
          <li *ngFor="let section of taskPlan?.TaskPlanSections; index as sectionIndex"
            [class.active]="section.Id === selectedSection?.Id" class="taskplanssection__container">
            <a class="clearfix taskplansection__action" (click)="selectSection(section)">
              {{section.Description}}
              <div class="pull-right">
                <button *ngIf="sectionIndex &gt; 0" type="button" class="btn btn-link taskplansection__actions--moveup"
                  (click)="moveSectionUp(section, sectionIndex)">
                  <span class="glyphicon glyphicon-chevron-up">&nbsp;</span>
                </button>
                <button *ngIf="sectionIndex &lt; (taskPlan?.TaskPlanSections?.length-1)" type="button"
                  (click)="moveSectionDown(section, sectionIndex)"
                  class="btn btn-link taskplansection__actions--movedown">
                  <span class="glyphicon glyphicon-chevron-down">&nbsp;</span>
                </button>
                <a [routerLink]="['/functional-review/checklists-section-edit', {sectionId:section.Id, taskPlanId: taskPlan?.Id, businessUnitId:taskPlan?.BusinessUnitId }]"
                  queryParamsHandling="merge" class="taskplansection__actions--edit">
                  <span class="glyphicon glyphicon-pencil">&nbsp;</span>
                </a>
                <button (click)="deleteSection(section)" class="btn btn-link taskplansection__actions--remove">
                  <span class="glyphicon glyphicon-remove">&nbsp;</span>
                </button>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>

    <div class="panel panel-default">
      <div class="panel-heading">
        <h3 class="panel-title new-section__header">
          {{'FR.Titles.CreateNewSection'|translate}}
        </h3>
      </div>
      <div class="panel-body">
        <form class="form-horizontal" #createNewSection="ngForm" (ngSubmit)="addSection(sectionDescription)">
          <div [class.has-error]="sectionDescription.invalid && !sectionDescription.pristine" class="form-group">
            <label for="sectionDescription" class="col-sm-3 control-label new-section__form-label">
              {{'FR.Forms.Labels.SectionDescription'|translate}}
            </label>
            <div class="col-sm-9">
              <input required name="sectionDescription" autofocus ngModel #sectionDescription="ngModel" type="text"
                class="form-control" placeholder="Description">
              <p class="text-danger" *ngIf="sectionDescription.invalid && !sectionDescription.pristine">
                {{ 'FR.Forms.Errors.InputRequired' | translate }}
              </p>
            </div>
          </div>

          <div class="text-right">
            <button [disabled]="createNewSection.invalid" type="submit"
              class="btn btn-primary">{{'FR.Actions.Create'|translate}}</button>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="col-md-8">
    <ng-container *ngIf="selectedSection !== null">
      <div class="panel panel-default">
        <div class="panel-heading">
          <h3 class="new-subsection__header">{{'FR.Titles.CreateNewSubSection'|translate}}</h3>
        </div>
        <div class="panel-body">
          <form class="form-horizontal" #createNewSubSection="ngForm" (ngSubmit)="addSubSection(subSectionDescription)">
            <div [class.has-error]="subSectionDescription.invalid && !subSectionDescription.pristine"
              class="form-group">
              <label for="subSectionDescription" class="col-sm-3 control-label new-subsection__form-label">
                {{'FR.Forms.Labels.SubsectionDescription'|translate}}
              </label>
              <div class="col-sm-9">
                <input required name="subSectionDescription" autofocus ngModel #subSectionDescription="ngModel"
                  type="text" class="form-control" placeholder="Description">
                <p class="text-danger" *ngIf="subSectionDescription.invalid && !subSectionDescription.pristine">
                  {{ 'FR.Forms.Errors.InputRequired' | translate }}
                </p>
              </div>
            </div>
            <div class="text-right">
              <button [disabled]="createNewSubSection.invalid" type="submit"
                class="btn btn-primary">{{'FR.Actions.Create'|translate}}</button>
            </div>
          </form>
        </div>
      </div>
      <ng-container *ngFor="let subSection of selectedSection?.TaskPlanSubSections; index as subSectionIndex">
        <div class="panel panel-primary">
          <div class="panel-heading">
            <div class="clearfix">
              <div class="pull-left">
                <h3 class="taskplansubsection__header--title">{{subSection.Description}}</h3>
              </div>
              <div class="pull-right taskplansubsection__actions">
                <button (click)="moveSubSectionUp(subSection, subSectionIndex)" *ngIf="subSectionIndex &gt; 0"
                  type="button" class="btn btn-link taskplansubsection__actions--moveup">
                  <span class="glyphicon glyphicon-chevron-up">&nbsp;</span>
                </button>
                <button (click)="moveSubSectionDown(subSection, subSectionIndex)"
                  *ngIf="subSectionIndex &lt; (selectedSection?.TaskPlanSubSections?.length -1)" type="button"
                  class="btn btn-link taskplansubsection__actions--movedown">
                  <span class="glyphicon glyphicon-chevron-down">&nbsp;</span>
                </button>
                <a [routerLink]="['/functional-review/checklists-sub-section-edit', 
                  { sectionId: selectedSection?.Id, businessUnitId: taskPlan?.BusinessUnitId, subSectionId:subSection.Id, taskPlanId: taskPlan?.Id}]"
                  queryParamsHandling="merge" class="taskplansubsection__actions--edit">
                  <span class="glyphicon glyphicon-pencil">&nbsp;</span>
                </a>
                <button (click)="deleteSubSection(subSection)" class="btn btn-link taskplansubsection__actions--remove">
                  <span class="glyphicon glyphicon-remove">&nbsp;</span>
                </button>
              </div>
            </div>
          </div>
          <div class="panel-body">
            <div class="table-responsive">
              <table class="table table-hover table-condensed">
                <thead>
                  <tr>
                    <th>{{'FR.Tables.Headers.Code'|translate}}</th>
                    <th colspan="2">: {{'FR.Tables.Headers.Description'|translate}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="taskplanitem__container"
                    *ngFor="let taskPlanItem of subSection.TaskPlanItems; index as taskPlanItemIndex">
                    <td>
                      <a [routerLink]="['/functional-review/checklists-item-edit', {
                      taskPlanId: taskPlan?.Id,
                      sectionId: selectedSection?.Id,
                      businessUnitId: taskPlan?.BusinessUnitId,
                      itemId: taskPlanItem.Id
                    }]" queryParamsHandling="merge">
                        {{taskPlanItem.Code}}
                      </a>
                    </td>
                    <td [innerHTML]="taskPlanItem.Description"></td>
                    <td class="taskplanitem__actions-container">
                      <button (click)="moveItemUp(taskPlanItem, taskPlanItemIndex)" *ngIf="taskPlanItemIndex &gt; 0"
                        type="button" class="btn btn-link taskplanitem__actions--moveup">
                        <span class="glyphicon glyphicon-chevron-up">&nbsp;</span>
                      </button>
                      <button (click)="moveItemDown(taskPlanItem, taskPlanItemIndex)"
                        *ngIf="taskPlanItemIndex &lt; (subSection.TaskPlanItems.length-1)" type="button"
                        class="btn btn-link taskplanitem__actions--movedown">
                        <span class="glyphicon glyphicon-chevron-down">&nbsp;</span>
                      </button>
                      <button (click)="deleteItem(taskPlanItem)" type="button"
                        class="btn btn-link taskplanitem__actions--remove">
                        <span class="glyphicon glyphicon-remove">&nbsp;</span>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="text-right">

              <a [routerLink]="['/functional-review/checklists-item-create', {
                taskPlanId: taskPlan?.Id,
                businessUnitId: taskPlan?.BusinessUnitId,
                subSectionId: subSection.Id,
                sectionId: selectedSection?.Id
              }]" queryParamsHandling="merge" class="btn btn-primary">
                {{'FR.Actions.CreateNewItem'|translate}}
              </a>
            </div>
          </div>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <div class="col-xs-12" *ngIf="taskPlan !== null">
    <a [routerLink]="['/functional-review/checklists', {businessUnitId: taskPlan?.BusinessUnitId}]"
      queryParamsHandling="merge">{{'FR.Actions.BackToChecklists'|translate}}</a>
  </div>
</div>
<p-confirmDialog #confirmDialog>
  <p-footer>
    <button type="button" pButton icon="pi pi-check" [label]="'FR.Dialogs.Actions.Yes'|translate"
      (click)="confirmDialog.accept()"></button>
    <button type="button" pButton class="ui-button-secondary" icon="pi pi-times"
      [label]="'FR.Dialogs.Actions.No'|translate" (click)="confirmDialog.reject()"></button>
  </p-footer>
</p-confirmDialog>