<div class="table-responsive response-items__responsive-table-container">
  <table class="table table-condensed response-items__responsive-table" *ngIf="responseItems.length &gt; 0">
    <thead>
      <tr class="warning response-item__status"
        [class.response-item__status--answered]="responseItemStatus === ResponseStatus.Answered"
        [class.response-item__status--optional]="responseItemStatus === ResponseStatus.Optional"
        [class.response-item__status--need-user-attention]="responseItemStatus === ResponseStatus.NeedUserAttention">
        <th class="response-item__table-header--id">
          {{'FR.Tables.Headers.ID'|translate}}
        </th>
        <th class="response-item__table-header--priority">
          {{'FR.Tables.Headers.Priority'|translate}}
        </th>
        <th class="response-item__table-header--action">
          {{'FR.Tables.Headers.Action'|translate}}
        </th>
        <th class="response-item__table-header--comment">
          {{'FR.Tables.Headers.Comment'|translate}}
        </th>
        <th class="response-item__table-header--actions">
          &nbsp;
        </th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let item of responseItems">
        <app-responses-item-action-item *ngIf="item.IsActionItem" [actionItem]="item"></app-responses-item-action-item>
        <app-responses-item-comment *ngIf="!item.IsActionItem" [comment]="item"></app-responses-item-comment>
      </ng-container>
    </tbody>
  </table>
</div>