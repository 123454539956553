<div class="panel panel-default">
  <div class="panel-body checklist-edit__container">



    <h2>{{'FR.Titles.EditChecklist'|translate}}</h2>


    <div class="form-horizontal">
      <form #form="ngForm" (ngSubmit)="updateChecklist(form.value)">
        <div class="row checklists__form--helper">

          <div class="col-sm-9 col-sm-offset-3">

            <p class="help-block" [innerHTML]="'FR.SubTitles.ReviewTypeWarning'|translate"></p>
          </div>
        </div>

        <div class="form-group" [class.has-error]="Code.invalid && !Code.pristine">
          <label class="col-sm-3 control-label" for="Name">{{'FR.Forms.Labels.ReviewType'|translate}}</label>
          <div class="col-sm-9">
            <input class="form-control" ngModel #Code="ngModel" required maxlength="100" name="Code" id="Code">
            <p class="text-danger" *ngIf="Code.errors?.required && !Code.pristine">
              {{'FR.Forms.Errors.InputRequired'|translate}}
            </p>
            <p class="text-danger" *ngIf="Code.errors?.maxlength && !Code.pristine">
              {{'FR.Forms.Errors.ReviewTypeMaxLength'|translate:Code.errors}}
            </p>
            <p class="text-danger" *ngIf="Code.errors?.repeatedReviewType && !Code.pristine">
              {{'FR.Forms.Errors.RepeatedReviewType'|translate}}
            </p>
          </div>

        </div>

        <div class="form-group" [class.has-error]="Description.invalid && !Description.pristine">
          <label class="col-sm-3 control-label" for="Description">{{'FR.Forms.Labels.Description'|translate}}</label>
          <div class="col-sm-9">
            <input class="form-control" ngModel required name="Description" id="Description" #Description="ngModel">
            <p class="text-danger" *ngIf="Description.errors?.required && !Description.pristine">
              {{'FR.Forms.Errors.InputRequired'|translate}}
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-9 col-sm-offset-3 checklists__form">
            <button type="submit" [disabled]="form.invalid"
              class="btn btn-primary checklists__form--button">{{'FR.Actions.Save'|translate}}</button>
          </div>
        </div>
      </form>
    </div>

    <a [routerLink]="['/functional-review/checklists',{businessUnitId:businessUnitId}]" queryParamsHandling="merge">
      {{'FR.Actions.BackToChecklists'|translate}}</a>

  </div>
</div>