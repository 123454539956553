import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from '#services/shared/toastr.service';
import { ChecklistService } from 'functional-review/services/api/checklist.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { FunctionalReviewTaskPlanSubSection } from 'functional-review/models/functional-review-taskplan-subsection';
import { HttpErrorFormat } from 'functional-review/models/http-error-format';
import { FunctionalReviewSharedHelper } from 'functional-review/services/helpers/functional-review-shared.helper';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  selector: 'app-checklists-subsection-edit',
  templateUrl: './checklists-subsection-edit.component.html',
  styleUrls: ['./checklists-subsection-edit.component.scss'],
  providers: [TranslatePipe]
})
export class CheckListsSubSectionEditComponent implements OnInit, OnDestroy {
  @ViewChild(NgForm) form: NgForm;

  businessUnitId = 0;
  taskPlanId = 0;
  currentChecklistSubSection: FunctionalReviewTaskPlanSubSection = null;

  private subscription = new Subscription();

  constructor(
    private checklistService: ChecklistService,
    private toastrService: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private translatePipe: TranslatePipe,
    private functionalReviewSharedHelper: FunctionalReviewSharedHelper
  ) {

    this.businessUnitId = +this.route.snapshot.params.businessUnitId;
    this.taskPlanId = +this.route.snapshot.params.taskPlanId;
  }

  ngOnInit() {

    const $checkList: Observable<FunctionalReviewTaskPlanSubSection> = this.checklistService.getChecklistSubSectionById(
      +this.route.snapshot.params.subSectionId
    );

    this.subscription.add(
      $checkList
        .subscribe((data) => {
          this.currentChecklistSubSection = data;

          this.form.setValue({ Description: data.Description });
        })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  updateChecklistSubSection(checkListSubSectionForm: Partial<FunctionalReviewTaskPlanSubSection>) {
    const payload = {
      Id: this.currentChecklistSubSection.Id,
      Description: checkListSubSectionForm.Description.trim(),
      SortID: this.currentChecklistSubSection.SortID,
      TaskPlanSectionId: this.currentChecklistSubSection.TaskPlanSectionId,
    } as FunctionalReviewTaskPlanSubSection;

    const checkList$ = this.checklistService.updateSubSection(payload);

    this.subscription.add(checkList$.subscribe(() => {
      this.toastrService.showSuccess(
        this.translatePipe.transform('FR.Messages.ChecklistSubSectionUpdated')
      );

      this.router.navigate(['/functional-review/checklists-manage', {
        taskPlanId: this.taskPlanId,
        businessUnitId: this.businessUnitId,
        sectionId: this.currentChecklistSubSection.TaskPlanSectionId,
      }], {
        queryParamsHandling: 'merge'
      });

    }, ({ error }: HttpErrorFormat) => {

      const { details, summary } = this.functionalReviewSharedHelper.getWebError({ error });

      this.toastrService.showError(details, summary);

    }));
  }
}
