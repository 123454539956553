import { Utility } from '#services/shared/utility';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslatePipe } from '@ngx-translate/core';
import { FunctionalReviewTaskPlan } from 'functional-review/models/functional-review-taskplan';
import { FunctionalReviewTaskPlanItem } from 'functional-review/models/functional-review-taskplan-item';
import { FunctionalReviewTaskPlanSection } from 'functional-review/models/functional-review-taskplan-section';
import { FunctionalReviewTaskPlanSubSection } from 'functional-review/models/functional-review-taskplan-subsection';
import { ChecklistService } from 'functional-review/services/api/checklist.service';
import { FunctionalReviewChecklistHelper } from 'functional-review/services/helpers/functional-review-checklist.helper';
import { ConfirmationService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { share, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-checklists-manage',
  providers: [ConfirmationService, TranslatePipe],
  templateUrl: './checklists-manage.component.html',
  styleUrls: ['./checklists-manage.component.scss'],

})
export class CheckListsManageComponent implements OnInit, OnDestroy {
  taskPlan: FunctionalReviewTaskPlan | null = null;
  selectedSection: FunctionalReviewTaskPlanSection | null = null;

  private subscription = new Subscription();

  constructor(
    private confirmationService: ConfirmationService,
    private checklistService: ChecklistService,
    private route: ActivatedRoute,
    private router: Router,
    private functionalReviewHelper: FunctionalReviewChecklistHelper,
    private translatePipe: TranslatePipe
  ) {

  }

  private loadChecklist(taskPlanId: number) {
    const checkListDetails$ = this.checklistService.getChecklistDetails(taskPlanId)
      .pipe(
        share()
      );

    this.subscription.add(
      checkListDetails$.subscribe(data => {
        this.taskPlan = data;

        this.loadSelectedSection(data);
      })
    );

    return checkListDetails$;
  }

  private loadSelectedSection(data: FunctionalReviewTaskPlan) {
    if (this.selectedSection !== null) {
      return;
    }
    if ('sectionId' in this.route.snapshot.params) {
      const section = data.TaskPlanSections.find(s => s.Id === +this.route.snapshot.params.sectionId);
      this.selectSection(
        typeof section === 'undefined' ? null : section
      );
    } else {
      this.selectSection(data.TaskPlanSections.length > 0 ? data.TaskPlanSections[0] : null);
    }
  }

  selectSection(section: FunctionalReviewTaskPlanSection) {
    this.selectedSection = section;

    if (section === null) {
      return;
    }

    this.router.navigate(['/functional-review/checklists-manage', {
      businessUnitId: this.taskPlan.BusinessUnitId,
      taskPlanId: this.taskPlan.Id,
      sectionId: section.Id
    }], {
      queryParamsHandling: 'merge'
    });
  }

  addSection(newSectionForm: FormControl) {
    const latestSortId = Utility.max(this.taskPlan.TaskPlanSections.map(s => s.SortID));

    const payload = {
      TaskPlanId: this.taskPlan.Id,
      Description: newSectionForm.value,
      SortID: latestSortId + 1,
    } as FunctionalReviewTaskPlanSection;
    const checklistSection$ = this.checklistService.addSection(payload)
      .pipe(
        switchMap((newSection) =>
          this.loadChecklist(this.taskPlan.Id)
            .pipe(
              tap(() => {
                this.selectSection(newSection);
              })
            )
        )
      );

    this.subscription.add(
      checklistSection$.subscribe(() => {
        newSectionForm.reset();
      })
    );
  }

  addSubSection(newSubSectionForm: FormControl) {
    const latestSortId = Utility.max(this.selectedSection.TaskPlanSubSections.map(s => s.SortID));

    const payload = {
      Description: newSubSectionForm.value,
      TaskPlanSectionId: this.selectedSection.Id,
      SortID: latestSortId + 1
    } as FunctionalReviewTaskPlanSubSection;
    const checklistSubSection$ = this.checklistService.addSubSection(payload)
      .pipe(
        switchMap((newSubSection) =>
          this.loadChecklist(this.taskPlan.Id)
            .pipe(
              tap(() => {
                this.selectSection(
                  this.functionalReviewHelper.findSectionBySubSection(this.taskPlan, newSubSection)
                );
              })
            )
        )
      );

    this.subscription.add(
      checklistSubSection$.subscribe(() => {
        newSubSectionForm.reset();
      })
    );
  }

  deleteSection(section: FunctionalReviewTaskPlanSection) {
    this.confirmDeletion(() => {
      this.subscription.add(
        this.checklistService.deleteSection(section.Id)
          .pipe(

            switchMap(() =>
              this.loadChecklist(this.taskPlan.Id)
                .pipe(
                  tap(() => {
                    this.selectSection(null);
                  })
                )
            )
          )
          .subscribe()
      );
    });
  }

  deleteSubSection(subSection: FunctionalReviewTaskPlanSubSection) {
    this.confirmDeletion(() => {
      this.subscription.add(
        this.checklistService.deleteSubSection(subSection.Id)
          .pipe(
            switchMap(() =>
              this.loadChecklist(this.taskPlan.Id)
                .pipe(
                  tap(() => {
                    this.selectSection(

                      this.functionalReviewHelper.findSectionBySubSection(this.taskPlan, subSection)
                    );
                  })
                )

            )
          )
          .subscribe()
      );
    });
  }

  deleteItem(item: FunctionalReviewTaskPlanItem) {
    this.confirmDeletion(() => {
      this.subscription.add(
        this.checklistService.deleteItem(item.Id)
          .pipe(
            switchMap(() =>
              this.loadChecklist(this.taskPlan.Id)
                .pipe(
                  tap(() => {
                    this.selectSection(
                      this.functionalReviewHelper.findSectionByItem(this.taskPlan, item)
                    );
                  })
                )

            )
          )
          .subscribe()
      );
    });

  }
  private confirmDeletion(confirmDeletionFn: () => void) {
    this.confirmationService.confirm({
      message: this.translatePipe.transform('FR.Dialogs.Description.Delete'),
      header: this.translatePipe.transform('FR.Dialogs.Title.Delete'),
      accept: confirmDeletionFn
    });
  }

  moveSectionUp(section: FunctionalReviewTaskPlanSection, currentIndex: number) {

    const payload: FunctionalReviewTaskPlanSection[] = this.functionalReviewHelper.getMovedUpSectionsPayload(this.taskPlan.TaskPlanSections, section, currentIndex);
    const checkListSections$ = this.checklistService.updateSections(payload)
      .pipe(
        switchMap(
          () =>
            this.loadChecklist(this.taskPlan.Id)
              .pipe(
                tap(() => {
                  this.selectSection(section);
                })
              )
        )
      );

    this.subscription.add(
      checkListSections$.subscribe()
    );
  }

  moveSectionDown(section: FunctionalReviewTaskPlanSection, currentIndex: number) {
    const payload: FunctionalReviewTaskPlanSection[] = this.functionalReviewHelper.getMovedDownSectionsPayload(this.taskPlan.TaskPlanSections, section, currentIndex);

    const checkListItem$ = this.checklistService.updateSections(payload)
      .pipe(
        switchMap(
          () =>
            this.loadChecklist(this.taskPlan.Id)
              .pipe(
                tap(() => {
                  this.selectSection(
                    section
                  );
                })
              )
        )
      );

    this.subscription.add(
      checkListItem$.subscribe()
    );
  }

  moveItemUp(item: FunctionalReviewTaskPlanItem, currentIndex: number) {
    const payload = this.functionalReviewHelper.getMovedUpItemsPayload(
      this.selectedSection, item, currentIndex
    );
    const checkListItem$ = this.checklistService.updateItems(payload)
      .pipe(
        switchMap(
          () =>
            this.loadChecklist(this.taskPlan.Id)
              .pipe(
                tap(() => {
                  this.selectSection(
                    this.functionalReviewHelper.findSectionByItem(this.taskPlan, item)
                  );
                })
              )
        )
      );

    this.subscription.add(
      checkListItem$.subscribe()
    );
  }

  moveItemDown(item: FunctionalReviewTaskPlanItem, currentIndex: number) {
    const payload = this.functionalReviewHelper.getMovedDownItemsPayload(this.selectedSection, item, currentIndex);

    const checkListItem$ = this.checklistService.updateItems(payload)
      .pipe(
        switchMap(
          () =>
            this.loadChecklist(this.taskPlan.Id)
              .pipe(
                tap(() => {
                  this.selectSection(

                    this.functionalReviewHelper.findSectionByItem(this.taskPlan, item)

                  );
                })
              )
        )
      );

    this.subscription.add(
      checkListItem$.subscribe()
    );
  }

  moveSubSectionUp(subSection: FunctionalReviewTaskPlanSubSection, currentIndex: number) {
    const payload = this.functionalReviewHelper.getMovedUpSubSectionsPayload(this.selectedSection.TaskPlanSubSections, subSection, currentIndex);

    const checkListItem$ = this.checklistService.updateSubSections(payload).pipe(
      switchMap(
        () =>
          this.loadChecklist(this.taskPlan.Id)
            .pipe(
              tap(() => {
                this.selectSection(
                  this.functionalReviewHelper.findSectionBySubSection(this.taskPlan, subSection)
                );
              })
            )
      )
    );

    this.subscription.add(
      checkListItem$.subscribe()
    );
  }
  moveSubSectionDown(subSection: FunctionalReviewTaskPlanSubSection, currentIndex: number) {
    const payload = this.functionalReviewHelper.getMovedDownSubSectionsPayload(this.selectedSection.TaskPlanSubSections, subSection, currentIndex);

    const checkListItem$ = this.checklistService.updateSubSections(payload)
      .pipe(
        switchMap(
          () =>
            this.loadChecklist(this.taskPlan.Id)
              .pipe(
                tap(() => {
                  this.selectSection(
                    this.functionalReviewHelper.findSectionBySubSection(this.taskPlan, subSection)
                  );
                })
              )
        )
      );

    this.subscription.add(
      checkListItem$.subscribe()
    );
  }

  ngOnInit() {
    this.loadChecklist(this.route.snapshot.params.taskPlanId);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
