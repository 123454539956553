import { Component, ElementRef, HostBinding, Input, OnDestroy, OnInit } from '@angular/core';
import { FunctionalReviewResponseGroupedBySection } from '../../../models/functional-review-response-grouped-by-section';
import { FunctionalReviewResponse } from 'functional-review/models/functional-review-response';
import { NgForm } from '@angular/forms';
import { ResponseService } from 'functional-review/services/api/response.service';
import { Subscription } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { ResponseStatus } from '#models/enum/response-status';
import { ToastrService } from '#services/shared/toastr.service';
import { FunctionalReviewComponent } from 'functional-review/pages/functional-review/functional-review.component';
import { FunctionalReviewResponseGroupedBySectionHelper } from 'functional-review/services/helpers/functional-review-response-grouped-by-section.helper';
import { FunctionalReviewResponseGroupedBySubSection } from 'functional-review/models/functional-review-response-grouped-by-subsection';
import { HttpErrorFormat } from 'functional-review/models/http-error-format';
import { FunctionalReviewSharedHelper } from 'functional-review/services/helpers/functional-review-shared.helper';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  selector: 'app-responses-section',
  templateUrl: './responses-section.component.html',
  styleUrls: ['./responses-section.component.scss'],
  host: {
    'class': 'panel-group',
    'role': 'tablist',
    'aria-multiselectable': 'true'
  },
  providers: [TranslatePipe]
})
export class ResponsesSectionComponent implements OnInit, OnDestroy {


  constructor(private responseService: ResponseService,
    private functionalReviewComponent: FunctionalReviewComponent,
    private responseHelper: FunctionalReviewResponseGroupedBySectionHelper,
    private toastrService: ToastrService,
    private el: ElementRef,
    private functionalReviewSharedHelper: FunctionalReviewSharedHelper,
    private translatePipe: TranslatePipe
  ) { }

  @Input() section: FunctionalReviewResponseGroupedBySection = {
    Description: '',
    Id: 0,
    SortID: 0,
    SubSections: [],
    FunctionalReviewID: 0,
    TaskPlanDescription: '',
    Collapsed: false,
    Status: ResponseStatus.None
  };

  @HostBinding('id') accordionId: string;

  selectedSubSection: FunctionalReviewResponseGroupedBySubSection;
  newResponse: Partial<FunctionalReviewResponse>;
  // subSectionsList: SelectItem[];

  ResponseStatus = ResponseStatus;

  private _subscription = new Subscription();

  ngOnInit(): void {
    this.accordionId = 'section-accordion-' + this.section.Id;
    this.newResponse = {
      FunctionalReviewID: this.section.FunctionalReviewID,
      TaskPlanDescription: this.section.TaskPlanDescription,
      Id: 0,
      ItemCode: null,
      ResponseText: null,
      SectionDescription: this.section.Description,
      SectionID: this.section.Id,
      SectionSortID: this.section.SortID,
      ShouldAddCommentToQuestion: false,
    };
    // this.subSectionsList = this.section.SubSections.map(ss => ({ label: ss.Description, value: ss.Description }));
    this.selectedSubSection = this.section.SubSections.length > 0 ? this.section.SubSections[0] : null;


  }

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }


  addResponse(responseForm: NgForm) {
    const {
      SubSection = {
        Description: '',
        SortID: 0
      },
      ItemDescription = ''
    } = responseForm.value;


    this.newResponse.SubSectionDescription = SubSection.Description;
    this.newResponse.SubSectionSortID = SubSection.SortID;
    this.newResponse.ItemDescription = ItemDescription;

    const addResponse$ = this.responseService.addResponse(this.newResponse);

    this._subscription.add(
      addResponse$.pipe(
        switchMap(() => this.functionalReviewComponent.loadFunctionalReview())
      ).subscribe(() => {
        responseForm.reset({
          SubSectionDescription: this.selectedSubSection,
          ItemDescription: ''
        });

        this.toastrService.showSuccess(this.translatePipe.transform('FR.Messages.ResponseAdded'));

        this.scrollToNewResponse();
      }, ({ error }: HttpErrorFormat) => {

        const { details, summary } = this.functionalReviewSharedHelper.getWebError({ error });

        this.toastrService.showError(details, summary);

      })
    );

  }

  private scrollToNewResponse() {
    if (!this.el) {
      return;
    }
    const $el: HTMLElement = this.el.nativeElement;

    const $lastChild: HTMLElement = $el.querySelector('app-responses-item:last-child');
    scrollTo(0, $lastChild.offsetTop + $lastChild.clientHeight);
  }

  toggleCollapse() {
    this.responseHelper.toggleCollapseSection(this.section.Id.toString(), !this.section.Collapsed);
  }
}
