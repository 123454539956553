<td colspan="4">
  <b class="response-item-comment__title">{{'FR.Tables.Headers.Comment'|translate}}:</b>
  {{comment.Comment}}
</td>
<td>
  <div class="row text-center">
    <div class="col-md-6">
      <button type="button" class="response-item-comment__action btn btn-primary"
        (click)="editComment(comment)">{{'FR.Actions.Edit'|translate}}</button>
    </div>
    <div class="col-md-6">

      <button type="button" class="action-item__action btn btn-danger"
        (click)="deleteComment(comment.ID)">{{'FR.Actions.Delete'|translate}}</button>
    </div>
  </div>

</td>

<p-confirmDialog #confirmDialog [key]="'item-'+comment.ID">
  <p-footer>
    <button type="button" pButton icon="pi pi-check" [label]="'FR.Dialogs.Actions.Yes'|translate" (click)="confirmDialog.accept()"></button>
    <button type="button" pButton class="ui-button-secondary" icon="pi pi-times" [label]="'FR.Dialogs.Actions.No'|translate"
      (click)="confirmDialog.reject()"></button>
  </p-footer>
</p-confirmDialog>