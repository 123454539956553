import { ToastrService } from '#services/shared/toastr.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslatePipe } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-functional-review-layout',
  templateUrl: './functional-review-layout.component.html',
  styleUrls: ['./functional-review-layout.component.scss'],
  providers: [TranslatePipe]
})
export class FunctionalReviewLayoutComponent implements OnInit, OnDestroy {
  showContent = true;
  mocId: number;
  frChecklistRecordId: number;
  subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private toastrService: ToastrService,
    private translatePipe: TranslatePipe
  ) {

  }

  ngOnInit(): void {
    this.subscription.add(
      this.route.queryParams.subscribe(params => {
        this.frChecklistRecordId = +params['frChecklistRecordId'];
        this.mocId = +params['mocId'];

        if (this.frChecklistRecordId) {
          sessionStorage.setItem('frChecklistRecordId', this.frChecklistRecordId.toString());
        }
        if (this.mocId) {
          sessionStorage.setItem('mocId', this.mocId.toString());
        }

        this.showContent = !!this.mocId && !!this.frChecklistRecordId;
        if (!this.showContent) {
          this.toastrService.showError(
            this.translatePipe.transform(
              'FR.Errors.UnavailableMOC'
            )
          );
        }
      })
    );

  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
