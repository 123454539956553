import { Component, Input, OnDestroy } from '@angular/core';
import { FunctionalReviewResponseGroupedByItem } from '../../../models/functional-review-response-grouped-by-item';
import { FunctionalReviewResponseTextType } from '../../../models/functional-review-response-text-type';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { ResponseService } from '../../../services/api/response.service';
import { FunctionalReviewResponse } from '../../../models/functional-review-response';
import { Observable, Subscription } from 'rxjs';
import { exhaustMap, filter, switchMap, tap } from 'rxjs/operators';
import { ResponseStatus } from '#models/enum/response-status';
import { ToastrService } from '#services/shared/toastr.service';
import { FunctionalReviewComponent } from 'functional-review/pages/functional-review/functional-review.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ResponsesItemActionItemFormComponent } from '../responses-item-action-item-form/responses-item-action-item-form.component';
import { FunctionalReviewResponseItem } from 'functional-review/models/functional-review-response-item';
import { FunctionalReviewResponseItemPriority } from 'functional-review/models/functional-review-response-item-priority';
import { ResponseItemService } from 'functional-review/services/api/response-item.service';
import { HttpErrorFormat } from 'functional-review/models/http-error-format';
import { FunctionalReviewSharedHelper } from 'functional-review/services/helpers/functional-review-shared.helper';
import { ResponsesItemCommentFormComponent } from '../responses-item-comment-form/responses-item-comment-form.component';
import { TranslatePipe } from '@ngx-translate/core';
import { Utility } from '#services/shared/utility';

@Component({
  selector: 'app-responses-item',
  templateUrl: './responses-item.component.html',
  styleUrls: ['./responses-item.component.scss'],
  providers: [TranslatePipe]
})
export class ResponsesItemComponent implements OnDestroy {
  private _scrollTop: number;

  constructor(
    private responseService: ResponseService,
    private responseItemService: ResponseItemService,
    private functionalReviewComponent: FunctionalReviewComponent,
    private toastrService: ToastrService,
    public dialog: MatDialog,
    private functionalReviewSharedHelper: FunctionalReviewSharedHelper,
    private translatePipe: TranslatePipe,
    private confirmationService: ConfirmationService
  ) {

  }
  @Input() item: FunctionalReviewResponseGroupedByItem = {
    Code: '',
    Description: '',
    Id: 0,
    ResponseText: null,
    ShouldAddCommentToQuestion: false,
    SortID: 0,
    UpdatedBy: '',
    UpdatedDate: new Date(),
    ResponseItems: [],
    Status: ResponseStatus.None
  };


  ResponseStatus = ResponseStatus;

  responseTextOptions: SelectItem[] = [
    { value: null, label: '' },
    { value: 'Yes', label: 'Yes' },
    { value: 'No', label: 'No' },
    { value: 'N/A', label: 'N/A' },
  ];

  private _subscription = new Subscription();

  ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  updateAnswer(answer: FunctionalReviewResponseTextType) {
    const updateAnswer: Observable<FunctionalReviewResponse> = this.responseService.updateAnswer(this.item.Id, answer);

    this._subscription.add(
      updateAnswer.pipe(
        tap(() => {
          this._scrollTop = window.pageYOffset;


        }),
        switchMap(() => this.functionalReviewComponent.loadFunctionalReview())
      ).subscribe(() => {
        this.toastrService.showSuccess(
          this.translatePipe.transform('FR.Messages.ResponseAnswered')
        );

        this.scrollToContent();
      }, ({ error }: HttpErrorFormat) => {

        const { details, summary } = this.functionalReviewSharedHelper.getWebError({ error });

        this.toastrService.showError(details, summary);

      })
    );
  }

  removeResponse(id: number) {
    const removeResponse$ = this.responseService.deleteResponse(id);

    this._subscription.add(
      removeResponse$.pipe(
        tap(() => {
          this._scrollTop = window.pageYOffset;

        }),
        switchMap(() => this.functionalReviewComponent.loadFunctionalReview())
      ).subscribe(() => {
        this.toastrService.showSuccess(this.translatePipe.transform('FR.Messages.ResponseRemoved'));

        this.scrollToContent();
      }, ({ error }: HttpErrorFormat) => {

        const { details, summary } = this.functionalReviewSharedHelper.getWebError({ error });

        this.toastrService.showError(details, summary);

      })
    );
  }
  scrollToContent() {
    Utility.delay(() => {
      scrollTo(0, this._scrollTop);

    }, 1);
  }

  addResponseItem() {
    const dialogRef: MatDialogRef<ResponsesItemActionItemFormComponent, FunctionalReviewResponseItem> =
      this.dialog.open(ResponsesItemActionItemFormComponent, {
        hasBackdrop: true,
        width: '670px',
        height: 'auto',
        maxWidth: 'none',
        panelClass: 'responses__form-dialog',
        data: {
          Action: '',

          IsActionItem: true,
          ResponseID: this.item.Id,
          Priority: FunctionalReviewResponseItemPriority.None,
          ID: 0,
          Comment: ''
        } as FunctionalReviewResponseItem
      });

    this._subscription.add(
      dialogRef.afterClosed()
        .pipe(
          filter((data) => typeof data !== 'undefined'),
          switchMap((responseItem) => this.responseItemService.addResponseItem(responseItem)),
          tap(() => {
            this._scrollTop = window.pageYOffset;

          }),
          exhaustMap(() => this.functionalReviewComponent.loadFunctionalReview())
        )
        .subscribe(
          () => {

            this.toastrService.showSuccess(this.translatePipe.transform('FR.Messages.ActionItemAdded'));

            this.scrollToContent();
          },
          ({ error }: HttpErrorFormat) => {
            const { details, summary } = this.functionalReviewSharedHelper.getWebError({ error });

            this.toastrService.showError(details, summary);
          }
        )
    );
  }

  addComment() {
    const dialogRef: MatDialogRef<ResponsesItemCommentFormComponent, FunctionalReviewResponseItem> = this.dialog.open(ResponsesItemCommentFormComponent, {
      hasBackdrop: true,
      width: '670px',
      height: 'auto',
      panelClass: 'responses__form-dialog',
      data: {
        Action: '',

        IsActionItem: false,
        ResponseID: this.item.Id,
        Priority: FunctionalReviewResponseItemPriority.None,
        ID: 0,
        Comment: ''
      } as FunctionalReviewResponseItem
    });

    this._subscription.add(
      dialogRef.afterClosed()
        .pipe(
          filter((data) => typeof data !== 'undefined'),
          switchMap((responseItem) => this.responseItemService.addResponseItem(responseItem)),
          tap(() => {
            this._scrollTop = window.pageYOffset;

          }),
          exhaustMap(() => this.functionalReviewComponent.loadFunctionalReview())
        )
        .subscribe(
          () => {
            this.toastrService.showSuccess(this.translatePipe.transform('FR.Messages.CommentAdded'));

            this.scrollToContent();
          },
          ({ error }: HttpErrorFormat) => {
            const { details, summary } = this.functionalReviewSharedHelper.getWebError({ error });

            this.toastrService.showError(details, summary);
          }
        )
    );

  }

  confirmRemoveResponse(id: number) {
    this.confirmationService.confirm({
      message: this.translatePipe.transform('FR.Dialogs.Description.Delete'),
      header: this.translatePipe.transform('FR.Dialogs.Title.Delete'),
      key: 'item-' + id,
      accept: () => {
        this.removeResponse(id);

      }
    });
  }
}
