<form #actionItemForm="ngForm" (ngSubmit)="submit(actionItemForm)">
  <div class="ui-dialog-titlebar ui-widget-header ui-helper-clearfix ui-corner-top">
    <span class="ui-dialog-title">{{'FR.Titles.ConfigureActionItem'|translate}}</span>
    <div class="ui-dialog-titlebar-icons">

      <button (click)="dialogRef.close()" type="button"
        class="btn btn-link ui-dialog-titlebar-icon ui-dialog-titlebar-close ui-corner-all">
        <span class="pi pi-times"></span>
      </button>
    </div>
  </div>
  <div class="ui-dialog-content ui-widget-content action-item-form__content">
    <div class="action-item-form__controls-container">
      <div [class.has-error]="Action.invalid && !Action.pristine" class="form-group">
        <label for="Action" class="control-label">{{ "FR.Forms.Labels.Action" | translate }}:</label>
        <input required autofocus type="text" [ngModel]="actionItem.Action" name="Action" #Action="ngModel"
          class="form-control" placeholder="Action">
        <p class="text-danger" *ngIf="Action.invalid && !Action.pristine">
          {{ 'FR.Forms.Errors.InputRequired' | translate }}
        </p>
      </div>

      <div class="form-group">
        <label class="control-label">{{ "FR.Forms.Labels.Priority" | translate }}:</label>

        <div class="response-item__priority-selection">
          <button *ngFor="let priorityOptions of functionalReviewResponseItemPriorityOptions" type="button"
            [class.response-item__priority--selected]="FunctionalReviewResponseItemPriority[priorityOptions.label] === actionItem.Priority"
            [class.response-item__priority--p2]="priorityOptions.value === 2"
            [class.response-item__priority--p3]="priorityOptions.value === 3"
            [class.response-item__priority--p4]="priorityOptions.value === 4"
            (click)="chipClicked(priorityOptions.value)">
            {{priorityOptions.label}}
          </button>
        </div>
      </div>

      <div class="form-group">
        <label for="Comment" class="control-label">{{ 'FR.Forms.Labels.Comment' | translate }}:</label>
        <textarea [ngModel]="actionItem.Comment" name="Comment" #Comment="ngModel"
          class="action-item-form__comment-control form-control" placeholder="Comment"></textarea>
      </div>
    </div>

    <div class="action-item-form__audition-container">
      <p *ngIf="actionItem.CreatedBy">
        <strong>{{ 'FR.Forms.Labels.CreatedBy' | translate }}:</strong>&nbsp;{{actionItem.CreatedBy}}
      </p>
      <p *ngIf="actionItem.UpdatedDate">
        <strong>{{ 'FR.Forms.Labels.UpdatedDate' | translate }}:</strong>&nbsp;{{actionItem.UpdatedDate|date:'short'}}
      </p>
      <p *ngIf="actionItem.UpdatedBy">
        <strong>{{ 'FR.Forms.Labels.UpdatedBy' | translate }}:</strong>&nbsp;{{actionItem.UpdatedBy}}
      </p>
    </div>
  </div>

  <div class="ui-dialog-footer ui-widget-content">
    <button type="submit" [disabled]="actionItemForm.invalid || !actionItem.Priority" class="btn btn-primary">
      {{ 'FR.Actions.Save' | translate }}
    </button>
    <button type="button" class="btn btn-default" (click)="dialogRef.close()">
      {{ 'FR.Actions.Cancel' | translate}}
    </button>
  </div>
</form>