
import { Component, Input, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FunctionalReviewResponseItem } from 'functional-review/models/functional-review-response-item';
import { Subscription } from 'rxjs';
import { ResponsesItemCommentFormComponent } from '../responses-item-comment-form/responses-item-comment-form.component';
import { exhaustMap, filter, switchMap, tap } from 'rxjs/operators';
import { ToastrService } from '#services/shared/toastr.service';
import { ResponseItemService } from 'functional-review/services/api/response-item.service';
import { FunctionalReviewComponent } from 'functional-review/pages/functional-review/functional-review.component';
import { FunctionalReviewSharedHelper } from 'functional-review/services/helpers/functional-review-shared.helper';
import { HttpErrorFormat } from 'functional-review/models/http-error-format';
import { ConfirmationService } from 'primeng/api';
import { Utility } from '#services/shared/utility';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  selector: 'app-responses-item-comment',
  templateUrl: './responses-item-comment.component.html',
  styleUrls: ['./responses-item-comment.component.scss'],
  providers: [TranslatePipe]
})
export class ResponsesItemCommentComponent implements OnDestroy {

  @Input() comment: FunctionalReviewResponseItem;

  private _subscription = new Subscription();
  private _scrollTop = 0;

  editComment(comment: FunctionalReviewResponseItem) {
    const dialogRef = this.dialog.open(
      ResponsesItemCommentFormComponent, {
      hasBackdrop: true,
      width: '670px',
      height: 'auto',
      panelClass: 'responses__form-dialog',
      data: { ...comment }
    });
    this._subscription.add(
      dialogRef.afterClosed()
        .pipe(
          filter((data) => typeof data !== 'undefined'),
          switchMap((data) => this.responseItemService.editResponseItem(data)),
          tap(() => {
            this._scrollTop = window.pageYOffset;

          }),
          exhaustMap(() => this.functionalReviewComponent.loadFunctionalReview())
        )
        .subscribe(() => {
          this.toastrService.showSuccess(
            this.translatePipe.transform('FR.Messages.CommentUpdated')
          );

          this.scrollToContent();
        },
          ({ error }: HttpErrorFormat) => {
            const { details, summary } = this.functionalReviewSharedHelper.getWebError({ error });

            this.toastrService.showError(details, summary);
          }
        )

    );
  }

  deleteComment(commentId: number) {
    this.confirmationService.confirm({
      message: this.translatePipe.transform('FR.Dialogs.Description.Delete'),
      header: this.translatePipe.transform('FR.Dialogs.Title.Delete'),
      key: 'item-' + commentId,
      accept: () => {
        const deleteResponseItem$ = this.responseItemService.deleteResponseItem(commentId)
          .pipe(
            tap(() => {
              this._scrollTop = window.pageYOffset;
            }),
            switchMap(() => this.functionalReviewComponent.loadFunctionalReview())
          );

        this._subscription.add(deleteResponseItem$.subscribe(() => {
          this.toastrService.showSuccess(
            this.translatePipe.transform('FR.Messages.CommentDeleted')
          );

          this.scrollToContent();
        }, ({ error }: HttpErrorFormat) => {
          const { details, summary } = this.functionalReviewSharedHelper.getWebError({ error });

          this.toastrService.showError(details, summary);
        }));

      }
    });
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

  scrollToContent() {
    Utility.delay(() => {
      scrollTo(0, this._scrollTop);

    }, 1);
  }

  constructor(
    public dialog: MatDialog,
    private responseItemService: ResponseItemService,
    private functionalReviewComponent: FunctionalReviewComponent,
    private toastrService: ToastrService,
    private functionalReviewSharedHelper: FunctionalReviewSharedHelper,
    private confirmationService: ConfirmationService,
    private translatePipe: TranslatePipe
  ) {

  }
}
