<div class="panel panel-default">
  <div class="panel-body checklist-section-edit__container">


    <h2> {{'FR.Titles.EditChecklistSection'|translate}}</h2>

    <div class="form-horizontal">
      <form #form="ngForm" (ngSubmit)="updateChecklistSection(form.value)">
        <div class="form-group" [class.has-error]="Description.invalid && !Description.pristine">
          <label class="col-sm-3 control-label" for="Description">{{'Description'|translate}}</label>
          <div class="col-sm-9">
            <input class="form-control" required id="Description" name="Description" #Description="ngModel" ngModel>
            <p class="text-danger" *ngIf="Description.errors?.required && !Description.pristine">
              {{'FR.Forms.Errors.InputRequired'|translate}}
            </p>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-9 col-sm-offset-3 checklists__form">
            <button type="submit" [disabled]="form.invalid"
              class="btn btn-primary checklists__form--button">{{'FR.Actions.Save'|translate}}</button>
          </div>
        </div>
      </form>
    </div>

    <a [routerLink]="['/functional-review/checklists-manage',{
      taskPlanId: currentChecklistSection?.TaskPlanId,
      businessUnitId:businessUnitId,
      sectionId: currentChecklistSection?.Id
    }]" queryParamsHandling="merge">{{'FR.Actions.BackToChecklistsManage'|translate}}</a>

  </div>
</div>