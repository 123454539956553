<div class="panel panel-default">
  <div class="panel-body checklist-item-create__container">


    <h2>{{'FR.Titles.CreateChecklistItem'|translate}}</h2>

    <div class="form-horizontal">
      <form #form="ngForm" (ngSubmit)="addChecklistItem(form.value)">

        <div class="form-group" [class.has-error]="Code.invalid && !Code.pristine">
          <label class="col-sm-3 control-label" for="Code">
            {{'FR.Forms.Labels.Code'|translate}}
          </label>
          <div class="col-sm-9">
            <input class="form-control" required maxlength="100" name="Code" id="Code" ngModel #Code="ngModel">
            <p class="text-danger" *ngIf="Code.errors?.required && !Code.pristine">
              {{'FR.Forms.Errors.InputRequired'|translate}}
            </p>
            <p class="text-danger" *ngIf="Code.errors?.maxlength && !Code.pristine">
              {{'FR.Forms.Errors.ReviewTypeMaxLength'|translate:Code.errors}}
            </p>
            <p class="text-danger" *ngIf="Code.errors?.repeatedReviewType && !Code.pristine">
              {{'FR.Forms.Errors.RepeatedReviewType'|translate}}
            </p>
          </div>

        </div>

        <div class="form-group"
          [class.has-error]="Description.invalid && !Description.pristine">
          <label for="Description" class="col-sm-3 control-label">{{'FR.Forms.Labels.Description'|translate}}</label>
          <div class="col-sm-9">
            <ejs-richtexteditor name="Description" required id="Description" ngModel #Description="ngModel" [toolbarSettings]="toolbarSettings">
            </ejs-richtexteditor>
            <p class="text-danger"
              *ngIf="Description.errors?.required && !Description.pristine">
              {{'FR.Forms.Errors.InputRequired'|translate}}
            </p>
          </div>
        </div>

        <div class="form-group">
          <label class="control-label col-sm-3" for="ShouldAddCommentToQuestion">
            {{'FR.Forms.Labels.ShouldAddCommentToQuestion'|translate}}
          </label>
          <div class="col-sm-9">
            <input type="checkbox" name="ShouldAddCommentToQuestion" id="ShouldAddCommentToQuestion" ngModel #ShouldAddCommentToQuestion="ngModel">
          </div>
        </div>

        <div class="row">
          <div class="col-sm-9 col-sm-offset-3 checklists__form">
            <button type="submit" [disabled]="form.invalid"
              class="btn btn-primary checklists__form--button">{{'FR.Actions.Save'|translate}}</button>
          </div>
        </div>
      </form>
    </div>


    <a [routerLink]="['/functional-review/checklists-manage',{sectionId:sectionId,taskPlanId:taskPlanId,businessUnitId:businessUnitId}]"
      queryParamsHandling="merge">{{'FR.Actions.BackToChecklistsManage'|translate}}</a>

  </div>
</div>