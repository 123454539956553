<div class="panel panel-info">
  <div [class.sub-sections__status--answered]="subSection.Status === ResponseStatus.Answered"
    [class.sub-sections__status--optional]="subSection.Status === ResponseStatus.Optional"
    [class.sub-sections__status--need-user-attention]="subSection.Status === ResponseStatus.NeedUserAttention"
    class="panel-heading sub-sections__status" role="tab" [id]="'sub-section-'+sectionId+'-'+subSectionId">
    <h4 class="panel-title responses-subsection__header">
      <button class="btn btn-default" (click)="toggleCollapse()" data-toggle="collapse"
        [attr.data-parent]="'#subsection-accordion-'+sectionId+'-'+subSectionId" [attr.aria-expanded]="!subSection.Collapsed"
        [attr.aria-controls]="'collapse-sub-section-'+sectionId+'-'+subSectionId">
        <span class="pi" [class.pi-plus]="subSection.Collapsed" [class.pi-minus]="!subSection.Collapsed"></span>
      </button>
      {{subSection.Description}}
    </h4>
  </div>
  <div [id]="'collapse-sub-section-'+sectionId+'-'+subSectionId" [class.in]="!subSection.Collapsed" class="panel-collapse collapse"
    role="tabpanel" [attr.aria-labelledby]="'sub-section-'+sectionId+'-'+subSectionId">
    <div *ngIf="subSection.Items.length" class="panel-body responses-items__container">
      <div class="table-responsive responses-items__table-container">
        <table class="table table-condensed">
          <thead>
            <tr class="sub-sections__status active"
              [class.sub-sections__status--answered]="subSection.Status === ResponseStatus.Answered"
              [class.sub-sections__status--optional]="subSection.Status === ResponseStatus.Optional"
              [class.sub-sections__status--need-user-attention]="subSection.Status === ResponseStatus.NeedUserAttention">
              <th class="responses-item__header--code">{{'FR.Tables.Headers.Code'|translate}}</th>
              <th class="responses-item__header--question">{{'FR.Tables.Headers.Question'|translate}}</th>
              <th class="responses-item__header--answer">{{'FR.Tables.Headers.Answer'|translate}}</th>
              <th class="responses-item__header--audition">{{'FR.Tables.Headers.UpdatedBy'|translate}}/{{'FR.Forms.Labels.UpdatedDate'|translate}}</th>

            </tr>
          </thead>


          <app-responses-item [item]="item" *ngFor="let item of subSection.Items">
          </app-responses-item>


        </table>
      </div>
    </div>
  </div>
</div>