import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  EventEmitter,
  Output,
} from '@angular/core';
import { PSSR, PSSRResponse } from 'models';
import { ResponseItemImage } from '#models/response-item-image';
import { Utility } from '#services/shared/utility';
import html2canvas from 'html2canvas';
import { PSSRResponseService } from '#services/api';

import { UserRole } from '#models/enum/user-role';
import { PSSRStatus } from '#models/pssr-status';
import { ItemType } from '#models/enum/item-type';

@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss'],
})
export class SignatureComponent implements OnInit {
  @Input() pssr: PSSR = new PSSR();
  @Input() response: PSSRResponse = new PSSRResponse();
  @Input() isFinalSignature: boolean;
  @Input() allowMembersToCompletePSSR = false;

  @Output() signed = new EventEmitter<PSSRResponse>();
  @ViewChild('signatureTemplate') signatureTemplate: ElementRef;

  display: boolean;
  selectedImage: any;
  singleSignature: Partial<ResponseItemImage>;

  today = new Date();

  get canUpdateSignature() {
    return (
      (this.response.CanAnswer || this.allowMembersToCompletePSSR) &&
      this.pssr.Status >= PSSRStatus.InProgress
    );
  }

  get canRemoveSignature() {
    return (
      (this.response.CanAnswer || this.allowMembersToCompletePSSR) &&
      ((this.response.ItemType === ItemType.WalkdownSignature &&
        (this.pssr.Status === PSSRStatus.InProgress ||
          this.pssr.Status === PSSRStatus.Completed)) ||
        (this.response.ItemType === ItemType.Signature &&
          this.pssr.Status === PSSRStatus.InProgress))
    );
  }

  constructor(

    private responseService: PSSRResponseService
  ) {}

  roles = {};

  ngOnInit() {
    this.roles = {
      [UserRole.Lead]: 'PSSR Team Lead',
      [UserRole.Team]: 'PSSR Team',
      [UserRole.OpsRep]: 'Operations Representative',
      [UserRole.Approver]: 'Final Approver',
    };

    this.singleSignature = null;
    if (
      !this.isFinalSignature &&
      Utility.isValidObj(this.response) &&
      Utility.isValidListWithData(this.response.ActionItems) &&
      Utility.isValidListWithData(this.response.ActionItems[0].Images)
    ) {
      this.singleSignature = this.response.ActionItems[0].Images[0];
    }
  }

  async sign() {



      const canvas = await html2canvas(this.signatureTemplate.nativeElement);
      const signature = canvas.toDataURL('image/png');

      const response = await this.responseService
        .signResponse({
          response: this.response,
          signatureDate: this.today,
          signature: signature.replace('data:image/png;base64,', ''),
          signedBy: this.pssr.CurrentUser.UserName,
        })
        .toPromise();

      this.response = response;
      this.singleSignature = this.response.ActionItems[0].Images[0];

      this.signed.emit(response);

  }

  async removeSignature() {


      this.response.ActionItems[0].Images[0].IsDeleted = true;
      await this.responseService
        .uploadImage(this.response.ActionItems[0].Images[0])
        .toPromise();
      await this.responseService
        .deleteResponseItem(this.response.ActionItems[0].Id)
        .toPromise();
      this.response.ActionItems = [];
      this.singleSignature = null;
      const ix = this.pssr.lstResponse.findIndex(
        (r) => r.Id === this.response.Id
      );
      this.pssr.lstResponse[ix].ActionItems = [];
      this.signed.emit(this.response);

  }

  expand(image: string) {
    this.display = true;
    this.selectedImage = image;
  }

  collapse() {
    this.display = false;
    this.selectedImage = '';
  }

}
