
import { Component, Inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FunctionalReviewResponseItem } from 'functional-review/models/functional-review-response-item';

@Component({
  selector: 'app-responses-item-comment-form',
  templateUrl: './responses-item-comment-form.component.html',
  styleUrls: ['./responses-item-comment-form.component.scss'],
  host: {
    class: 'ui-dialog'
  }
})
export class ResponsesItemCommentFormComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public comment: FunctionalReviewResponseItem,
    public dialogRef: MatDialogRef<ResponsesItemCommentFormComponent>
  ) {

  }


  submit(form: NgForm) {
    const { Comment = '' } = form.value;
    const { ID = 0, ResponseID } = this.comment;

    this.dialogRef.close({
      Comment,
      ID,
      ResponseID,
      IsActionItem: false
    });
  }
}
