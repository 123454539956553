<form #commentForm="ngForm" (ngSubmit)="submit(commentForm)">

  <div class="ui-dialog-titlebar ui-widget-header ui-helper-clearfix ui-corner-top">
    <span class="ui-dialog-title">{{'FR.Titles.ConfigureComment'|translate}}</span>
    <div class="ui-dialog-titlebar-icons">

      <button (click)="dialogRef.close()" type="button"
        class="btn btn-link ui-dialog-titlebar-icon ui-dialog-titlebar-close ui-corner-all">
        <span class="pi pi-times"></span>
      </button>
    </div>
  </div>

  <div class="ui-dialog-content ui-widget-content">
    <div class="comment-form__controls-container">
      <div [class.has-error]="Comment.invalid && !Comment.pristine" class="form-group">
        <label for="Comment" class="control-label">{{ "FR.Forms.Labels.Comment" | translate }}:</label>
        <textarea required autofocus [ngModel]="comment.Comment" name="Comment" #Comment="ngModel"
          class="comment-form__comment-control form-control" placeholder="Comment"></textarea>
        <p class="text-danger" *ngIf="Comment.invalid && !Comment.pristine">
          {{ 'FR.Forms.Errors.InputRequired' | translate }}
        </p>
      </div>
    </div>

    <div class="comment-form__audition-container">
      <p *ngIf="comment.CreatedBy">
        <strong>{{ 'FR.Forms.Labels.CreatedBy' | translate }}:</strong>&nbsp;{{comment.CreatedBy}}
      </p>
      <p *ngIf="comment.UpdatedDate">
        <strong>{{ 'FR.Forms.Labels.UpdatedDate' | translate }}:</strong>&nbsp;{{comment.UpdatedDate|date:'short'}}
      </p>
      <p *ngIf="comment.UpdatedBy">
        <strong>{{ 'FR.Forms.Labels.UpdatedBy' | translate }}:</strong>&nbsp;{{comment.UpdatedBy}}
      </p>
    </div>
  </div>

  <div class="ui-dialog-footer ui-widget-content">
    <button type="submit" [disabled]="commentForm.invalid" class="btn btn-primary">
      {{ 'FR.Actions.Save' | translate }}
    </button>
    <button type="button" class="btn btn-default" (click)="dialogRef.close()">
      {{ 'FR.Actions.Cancel' | translate}}
    </button>
  </div>
</form>