<div class="box-shadow module">
  <h2>{{'FR.Titles.Checklist'|translate}}</h2>

  <div class="row row-no-gutters checklist__bu-selector">
    <!-- Business Unit Selector -->
    <div class="col-sm-3">
      <label>{{'FR.Filters.BusinessUnit' | translate}}</label>
      <p-dropdown id="selectBU" name="selectBU" [options]="businessUnits" [ngModel]="selectedBusinessUnit"
        [styleClass]="'checklist__business-unit'" (ngModelChange)="selectBusinessUnit($event)"
        optionLabel="Name" appendTo="body" [placeholder]="'Select Option' | translate"
        [disabled]="businessUnits.length === 0">
      </p-dropdown>
    </div>

    <div class="col-sm-3 pull-right text-right" >
      <a class="checklist__create" [routerLink]="['/functional-review/checklists-create',{ businessUnitId:selectedBusinessUnit?.Id }]"
        queryParamsHandling="merge">{{'FR.Actions.CreateNewChecklist'|translate}}</a>
    </div>

  </div>

  <div class="table-responsive" *ngIf="taskPlans.length &gt; 0">
    <table class="table checklist-table">
      <thead>
        <tr class="info">
          <th class="checklist-table__header--review-type">{{'FR.Tables.Headers.ReviewType'|translate}}</th>
          <th class="checklist-table__header--description">{{'FR.Tables.Headers.Description'|translate}}</th>
          <th class="checklist-table__header--actions">&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let taskPlan of taskPlans | paginate: { id: 'paginator', itemsPerPage: pagination.pageSize, currentPage: pagination.pageNumber, totalItems: pagination.totalCount }">
          <td>{{taskPlan.Code}}</td>
          <td>{{taskPlan.Description}}</td>
          <td class="text-right">
            <a [routerLink]="['/functional-review/checklists-edit',{ taskPlanId:taskPlan?.Id, businessUnitId:selectedBusinessUnit?.Id }]"
              queryParamsHandling="merge"
              class="checklist-table__actions--link visible-xs-block visible-sm-block visible-md-inline visible-lg-inline">{{'FR.Actions.Edit'|translate}}</a>
            <span class="hidden-sm hidden-xs">&#124;</span>
            <a [routerLink]="['/functional-review/checklists-manage', {taskPlanId:taskPlan?.Id}]"
              queryParamsHandling="merge"
              class="checklist-table__actions--link visible-xs-block visible-sm-block visible-md-inline visible-lg-inline">{{'FR.Actions.Manage'|translate}}</a>
            <span class="hidden-sm hidden-xs">&#124;</span>
            <button (click)="deleteTaskPlan(taskPlan.Id)"
              class="checklist-table__actions--link visible-xs-block visible-sm-block visible-md-inline visible-lg-inline btn btn-link">{{'FR.Actions.Delete'|translate}}</button>
          </td>
        </tr>
      </tbody>

    </table>
  </div>

  <div class="pagination-container text-right" *ngIf="pagination.totalCount &gt; pagination.pageSize">
    <pagination-controls id="paginator" class="pagination-control" (pageChange)="onPageChange($event)"
      [maxSize]="pagination.pageSize"></pagination-controls>
    <p>{{ pagination.currentRange }}</p>
  </div>
</div>

<p-confirmDialog #confirmDialog>
  <p-footer>
    <button type="button" pButton icon="pi pi-check" [label]="'FR.Dialogs.Actions.Yes'|translate" (click)="confirmDialog.accept()"></button>
    <button type="button" pButton class="ui-button-secondary" icon="pi pi-times" [label]="'FR.Dialogs.Actions.No'|translate"
      (click)="confirmDialog.reject()"></button>
  </p-footer>
</p-confirmDialog>