<nav class="navbar navbar-default navbar-default-subsite centered" role="navigation" style="z-index: 999;">
  <div class="visible-xs">
    <div class="navbar-header centered visible-xs">
      <div class="hamburger-mobile pull-left hidden-sm hidden-md hidden-lg dropdown dropdown-mobile">
        <a href="#" class="dropdown-toggle pull-left" data-toggle="dropdown"
          data-target="#navbar-brand-centered-subsite" aria-expanded="false">
          <span class="sr-only">
            {{ "Toggle navigation" | translate }}
          </span>
          <span class="glyphicon glyphicon-menu-hamburger black" aria-hidden="true"></span>
        </a>
      </div>
    </div>
    <div class="navbar-brand navbar-brand-centered visible-xs">
      <div class="hallmark-container">
        <a class="hallmark" href="/" title="Chevron"><img alt="Chevron" src="/assets/images/hallmark.png" width="36"
            height="40" /></a>
      </div>
      <div class="sitename-container vertical-align">
        <a class="sitename light-blue font-gotham" href="/">inside chevron</a>
      </div>
    </div>
    <div class="search search-mobile pull-right hidden-sm hidden-md hidden-lg dropdown dropdown-mobile">
      <a href="https://onesearch.sp.chevron.net/" class="dropdown-toggle pull-right">
        <span class="glyphicon glyphicon-search black" aria-hidden="true"></span>
        <span class="sr-only">{{ "toggle search" | translate }}</span>
      </a>
    </div>
  </div>

  <div class="collapse navbar-collapse js-navbar-collapse centered row hidden-xs font-gotham"
    id="navbar-brand-centered">
    <div class="navbar-brand navbar-brand-centered">
      <div class="hallmark-container">
        <a class="hallmark" href="/" title="Chevron"><img alt="Chevron" src="/assets/images/hallmark.png" width="42"
            height="46" /></a>
      </div>
      <div class="sitename-container vertical-align">
        <a class="sitename light-blue font-gotham" routerLink="/functional-review" queryParamsHandling="merge">
          {{ 'FR.Titles.FunctionalReview'|translate }}
        </a>
      </div>
    </div>
  </div>
</nav>

<nav class="navbar navbar-subsite centered" style="z-index: 999;" role="navigation">
  <div class="container-fluid">
    <div style="height: 100% !important"
      class="collapse navbar-collapse js-navbar-collapse centered row font-gotham navbar-mobile dropdown-menu *dropdown-menu-mobile"
      id="navbar-brand-centered-subsite">
      <div class="mobile-nav-container vertical-align flex-center centered">

        <ul class="nav navbar-nav primary">
          <li *ngIf="(currentUser$ | async)?.HasAccess"
            class="col-sm-4 dropdown level-1 offcanvas menu-hover clearfix offcanvas-toggle clickable theme-bg-light-blue">
            <ng-container>
              <a id="functionalReviewListMainMenu" routerLink="/functional-review" queryParamsHandling="merge">{{
                "FR.Titles.FunctionalReview" | translate }}</a>
            </ng-container>
          </li>
          <li *ngIf="(currentUser$ | async)?.HasAccess"
            class="col-sm-4 dropdown level-1 offcanvas menu-hover clearfix offcanvas-toggle clickable theme-bg-light-blue">
            <a id="functionalReviewChecklist" routerLink="/functional-review/checklists" queryParamsHandling="merge">{{
              "FR.Titles.Checklist" | translate }}</a>
          </li>
        </ul>
      </div>
      <div class="mobile-nav-backdrop visible-xs"></div>
    </div>
  </div>
</nav>