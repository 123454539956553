import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from '#services/shared/toastr.service';
import { ChecklistService } from 'functional-review/services/api/checklist.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { FunctionalReviewTaskPlan } from 'functional-review/models/functional-review-taskplan';
import { HttpErrorFormat } from 'functional-review/models/http-error-format';
import { FunctionalReviewSharedHelper } from 'functional-review/services/helpers/functional-review-shared.helper';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
  selector: 'app-checklists-edit',
  templateUrl: './checklists-edit.component.html',
  styleUrls: ['./checklists-edit.component.scss'],
  providers: [TranslatePipe]
})
export class CheckListsEditComponent implements OnInit, OnDestroy {
  @ViewChild(NgForm) form: NgForm;
  businessUnitId: number;

  private subscription = new Subscription();
  private currentCheckList: FunctionalReviewTaskPlan;


  constructor(
    private checklistService: ChecklistService,
    private toastrService: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private translatePipe: TranslatePipe,
    private functionalReviewSharedHelper: FunctionalReviewSharedHelper
  ) {
  }

  ngOnInit() {
    this.subscription.add(
      this.route.params.subscribe(({ businessUnitId }) => {
        this.businessUnitId = +businessUnitId;
      }));

    const $checkList = this.checklistService.getChecklistById(+this.route.snapshot.params.taskPlanId);
    this.subscription.add(
      $checkList
        .subscribe((data) => {
          this.currentCheckList = data;

          const { Code, Description } = data;
          this.form.setValue({ Code, Description });
        })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  updateChecklist(checklistForm: Partial<FunctionalReviewTaskPlan>) {
    const payload = {
      Code: checklistForm.Code.trim(),
      Description: checklistForm.Description.trim(),
      Id: this.currentCheckList.Id,
      BusinessUnitId: this.businessUnitId,
      IsDeleted: this.currentCheckList.IsDeleted
    } as FunctionalReviewTaskPlan;

    const checkList$ = this.checklistService.updateTaskPlan(payload);

    this.subscription.add(checkList$.subscribe(() => {
      this.toastrService.showSuccess(
        this.translatePipe.transform(
          'FR.Messages.ChecklistUpdated'
        )
      );


      this.router.navigate(['/functional-review/checklists', {
        businessUnitId: this.businessUnitId
      }], {
        queryParamsHandling: 'merge'
      });

    }, ({ error }: HttpErrorFormat) => {
      if (error.ExceptionMessage.includes('code')) {
        this.form.controls['Code'].setErrors({ repeatedReviewType: true });
      } else {
        const { details, summary } = this.functionalReviewSharedHelper.getWebError({ error });

        this.toastrService.showError(details, summary);
      }
    }));
  }
}
