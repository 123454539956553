import { DictionaryEntries } from './dictionary-entries';

export const NL: DictionaryEntries = {
  '&': '&',
  'AllBactionitemsmustbeclosed.': 'All B action items must be closed',
  '3rdPartyexternal': 'Derde (extern)',
  'ABChecklist': 'AB-checklist',
  'ABChecklistfor': 'AB-checklist voor',
  'ACTIONS': 'ACTIES',
  'AChecklist': 'Een checklist',
  'Action': 'Actie',
  'ActionConfirmation': 'Weet je zeker dat je deze actie wilt uitvoeren?',
  'ActionItemAssignee': 'Actiepunt Toegewezen persoon',
  'ActionItemisneededtocompletequestion': 'Actiepunt is nodig om vraag in te vullen',
  'ActionItems': 'Actiepunten',
  'ActionIsRequired': 'Actieveld is vereist',
  'ActionWillBeTransferred': 'Actie wordt overgedragen aan Operations',
  'Actions': 'Acties',
  'Actionwillbetransferredtooperations': 'Punt wordt overgedragen aan Operations',
  'Active': 'Actief',
  'Add': 'Voeg toe',
  'AddABChecklisttoProject': 'Voeg AB-checklist to aan het project',
  'AddABChecktoEndDevice': 'Voeg AB-check toe aan Eindapparaat',
  'AddComment': 'Voeg Opmerking toe',
  'AddFeedback': 'Voeg feedback toe',
  'AddGroup': 'Voeg groep toe',
  'AddNew': 'VOEG NIEUW TOE',
  'AddQuestion': 'Voeg Vraag toe',
  'AddRedLining': 'Voeg rode lijn toe',
  'AddSignature': 'VOEG HANDTEKENING TOE',
  'AddTemplate': 'Voeg template toe',
  'AddUser': 'Voeg Gebruiker toe',
  'AddVersion': 'Voeg versie toe',
  'AddYellowLining': 'Voeg gele lijn toe',
  'Addactionitem': 'Voeg Actiepunt toe',
  'AddtoEAM': 'Voeg toe aan EAM',
  'Alert': 'Waarschuwing',
  'Alltherelatedsectionssubsectionsitemswillberemoved': 'Alle betrokken onderdelen | subonderdelen | punten worden verwijderd',
  'Alltherelatedsubsectionsitemswillberemoved': 'Alle betrokken subonderdelen | punten worden verwijderd',
  'SendAllNotificationsToApproversInstruction': '*Indien Aangevinkt ontvangt de Definitieve Fiatteur alle meldingen',
  'AllP3actionmustbeclosedtosubmitforapproval': 'Alle {{P3Label}}-acties moeten gesloten zijn voor ze ingediend worden voor goedkeuring',
  'AllowAIOwnerToAttach': 'Sta Eigenaar Actiepunt toe toe te voegen',
  'AllowAIOwnerToAttachInstruction': 'Indien aangevinkt kan de Eigenaar van het Actiepunt  bestanden bijvoegen, zelfs bij elke status van het Actiepunt terwijl het PSSR niet gesloten is.',
  'AllowApproverToSubmitForApproval': 'Sta Definitieve Fiatteur toe dit in te dienen voor Goedkeuring',
  'AllowLeadsDeleteQuestions': 'Sta leidinggevenden toe vragen te verwijderen',
  'AllowMembersCompletePSSR': 'Sta leden toe PSSR pre-startup veiligheidsevaluatie in te vullen',
  'AllowMembersToCompletePSSR': 'Sta Leden toe de PSSR af te ronden',
  'AllowMultiApprover': 'Sta meer fiatteurs toe',
  'AllowNAResponses': 'Sta antwoord N.V.T.  toe',
  'AllowWetSignature': 'Sta fysieke handtekening PSSR pre-startup veiligheidsevaluatie toe',
  'Allpages': 'Alle pagina\'s',
  'Alltherelated': 'Alle betrokken onderdelen',
  'Alltherelateditemswillberemoved': 'Alle betrokken punten worden verwijderd',
  'AngularTemplatedocumentation': 'Documentatie Angular-sjablonen',
  'Answer': 'Antwoord',
  'AnswerallquestionsandsignaturestoCompletethePSSR': 'Vul alle vragen & handtekeningen in om de {{PssrTitle}} te voltooien',
  'AnswerallquestionssignaturestoCompletethePSSR': 'Vul alle vragen & handtekeningen in om de {{PssrTitle}} te voltooien',
  'Answerwillbecleared': 'Antwoord wordt gewist',
  'Approve': 'Keur goed',
  'Approvebywetsignature': 'Keur goed met fysieke handtekening',
  'Approved': 'Goedgekeurd',
  'ApprovedOn': 'Goedgekeurd op',
  'AreyousureyouwanttodeleteABChecklistbelow': 'Weet je zeker dat je de onderstaande AB-checklist wilt verwijderen?',
  'AreyousureyouwanttodeleteSubCategory': 'Weet je zeker dat je de subcategorie wilt wissen?',
  'Area': 'Gebied',
  'Areyousureyouwanttodelete': 'Weet je zeker dat je wilt verwijderen?',
  'AreyousureyouwanttodeleteTaskPlanbelow': 'Weet je zeker dat je het onderstaande taakplan wilt verwijderen?',
  'AssignedToLeadFinalApprover': 'Toegewezen aan Teamleider / Definitieve Fiatteur',
  'Assignedto': 'Toegewezen aan',
  'Assignedtome': 'Aan mij toegewezen',
  'Assignee': 'Toegewezen persoon',
  'Assignto': 'Wijs toe aan',
  'Attach': 'Voeg toe',
  'AttachedFiles': 'Bijgevoegde bestanden',
  'AutoLoadImages': 'Laad afbeeldingen automatisch',
  'Autoclosems': 'Automatisch sluiten (ms.)',
  'Available': 'Beschikbaar',
  'BChecklist': 'B-checklist',
  'BUAdmin': 'BU Admin',
  'BUAdmins': 'BU Admins',
  'Back': 'Terug',
  'BacktoSubCategoryList': 'Terug naar lijst subcategorieën',
  'BacktoTaskPlan': 'Terug naar Taakplan',
  'BackToList': 'Terug naar lijst',
  'Backto': 'Terug naar',
  'BacktoABChecksList': 'Terug naar AB-lijst checks',
  'BacktoAreaList': 'Terug naar lijst Gebieden',
  'BacktoGroupList': 'Terug naar lijst Groepen',
  'BacktoList': 'Terug naar Lijst',
  'BacktoProjectDetail': 'Terug naar Projectdetail',
  'BacktoProjectList': 'Terug naar lijst Projecten',
  'BacktoTaskPlanManage': 'Terug naar Beheren Taakplan',
  'Bug': 'Computerfout',
  'BuildNumber': 'Bouw Nummer',
  'BusinessUnit': 'Bedrijfseenheid',
  'BusinessUnitisrequired': 'Bedrijfseenheid is vereist',
  'BusinessUnits': 'Business Units',
  'By': 'Door',
  'ByDefaultProject': 'Project is standaard',
  'CCCORR': 'PSSR / CCC / ORR',
  'CanOnlyPerformAttachments': 'Kan alleen Bijlagen uitvoeren',
  'Cancel': 'Annuleer',
  'Canceled': 'Geannuleerd',
  'CaptureSignature': 'Leg Handtekening vast',
  'Categories': 'Categorieën',
  'ChangeRequestApproved': 'Wijzigingsverzoek Goedgekeurd',
  'ChangeRequestDenied': 'Wijzigingsverzoek Afgekeurd',
  'Chevron': 'Chevron',
  'ChevronCorporationAllrightsreserved': 'Chevron Corporation. Alle rechten voorbehouden.',
  'ChevronDirectory': 'Map Chevron',
  'ChevronDirectory/SharedUsers': 'Map Directory / Gedeelte Gebruikers',
  'ChevronIE': 'Chevron I&E',
  'ChevronSFT': 'Chevron SFT',
  'Clear': 'Wis',
  'ClearFilter': 'Wis filter',
  'ClearQuestion': 'Wis Vraag',
  'ClearSelection': 'Wis Selectie',
  'Close': 'Sluit',
  'CloseProjWarning': 'Wilt u dit project sluiten?',
  'CloseProject': 'Sluit Project',
  'Closed': 'Afgesloten',
  'ClosedBy': 'Afgesloten door',
  'ClosedOn': 'Afgesloten op',
  'Code': 'Code',
  'ComissionDate': 'Opdrachtdatum',
  'Comment': 'Opmerking',
  'Commentgoeshere': 'Opmerking hoort hier',
  'Commentisneededtocompletequestion': 'Opmerking is nodig om de vraag in te vullen',
  'CompleteTranslation': 'Voltooi Vertaling',
  'Completed': 'Ingevuld',
  'CompletedQuestions': 'Ingevulde vragen',
  'ConfigureActionItem': 'Configureer Actiepunt',
  'Confirm': 'Bevestig',
  'ConfirmDeleteHeader': 'Bevestig het wissen',
  'ConfirmDeleteMessage': 'Je staat op het punt om dit punt te verwijderen, wil je verder gaan?',
  'ConfirmDeletePSSRFileHeader': 'Bevestig verwijderen',
  'ConfirmDeletePSSRFileMessage': 'Bestand <b>{{fileName}}</b> wordt permanent verwijderd, verder gaan?<br/>',
  'ConfirmPSSRApprovalHeader': '',
  'ConfirmPSSRApprovalMessage': 'Weet je zeker dat je het wilt goedkeuren?',
  'ConfirmPSSRReOpenMessage': 'Are you sure you want to re-open this PSSR?',
  'ConfirmPSSRReOpenHeader': '',
  'ConfirmRejectionHeader': 'Bevestig Afwijzing',
  'ConfirmRejectionMessage': 'Als je het actiepunt afwijst, kun je dit PSSR pre-startup veiligheidsevaluatie niet goedkeuren. Onderaan de pagina moet je de status wijzigen in ‘inspectieronde voltooid’.',
  'ConfirmTranslationComplete': 'Weet je zeker dat Titel, Omschrijving, Actiepunten en Opmerkingen zijn vertaald?',
  'Confirmdelete': 'Bevestig verwijderen',
  'Confirmtoproceed': 'Bevestig <b>Confirm</b> om verder te gaan',
  'ContinueConfirmation': 'Je wilt doorgaan',
  'Contractor': 'Aannemer',
  'CopiedImage': 'Afbeelding <b>{{docName}}</b> is gekopieerd',
  'Copy': 'Kopieer',
  'CopyEquipmentstoProject': 'Kopieer Materialen naar Project',
  'CopyImage': 'Kopieer Afbeelding',
  'CopyImageError': 'Fout bij Kopiëren Afbeelding',
  'CopyfromProject': 'Kopieer van Project',
  'CopyingImage': 'Afbeelding aan het kopiëren...',
  'Copyto': 'Kopieer naar',
  'CostCode': 'Kostencode',
  'Create': 'Maak aan',
  'CreateNewItem': 'Maak Nieuw Punt aan',
  'CreateBusinessUnit': 'Maak Bedrijfseenheid aan',
  'CreateGroup': 'Maak Groep aan',
  'CreateNew': 'Maak Nieuw aan',
  'CreateNewSubCategory': 'Maak Nieuwe Subcategorie aan',
  'CreatePSSR': 'Maak {{PssrTitle}} aan',
  'CreatePSSRCCC': 'Maak PSSR / CCC aan',
  'CreatePSSRCCCORR': 'Maak PSSR / CCC / ORR aan',
  'CreatePSSRORR': 'Maak PSSR / ORR aan',
  'CreateProject': 'Maak Project aan',
  'CreateSubCategory': 'Maak Subcategorie aan',
  'CreateTraining': 'Maak Training aan',
  'CreateUser': 'Maak Gebruiker aan',
  'CreatedBy': 'Aangemaakt door',
  'CreatedOn': 'Aangemaakt op',
  'DBVersion': 'Versie Database',
  'Date': 'Datum',
  'DateCaptured': 'Datum vastgelegd',
  'Dates': 'Data',
  'Default': 'Standaard',
  'DefaultSummarySignatureDisclaimer': 'Standaarddisclaimer Handtekening Samenvatting',
  'DefaultWalkdownSignatureDisclaimer': 'Standaarddisclaimer Handtekening Inspectieronde',
  'Delete': 'Verwijder',
  'DeleteActionItem': 'Wis Actiepunt',
  'DeleteDevice': 'Ga je akkoord met het wissen van het geselecteerde apparaat',
  'DeleteEndDevice': 'Wis Eindapparaat',
  'DeleteEquipment': 'Wis Materiaal',
  'DeleteEquipmentTemplateGroup': 'Wis Materaalsjabloon Groep',
  'DeleteManufacturerModel': 'Wis Fabrikant - Model',
  'DeleteProject': 'Deze actie zorgt ervoor dat dit project en alle betrokken PSSR worden verwijderd. Wil je doorgaan?',
  'DeleteTaskPlanSection': 'Verwijder Onderdeel Taakplan',
  'DeleteTaskPlanSubSection': 'Verwijder Subonderdeel Taakplan',
  'DeleteselectedEquipmentTemplateGroup': 'Ga je akkoord met het wissen van het geselecteerde Materiaalsjabloon Groep?',
  'Description': 'Beschrijving',
  'DescriptionScope': 'Omschrijving & Bereik',
  'Descriptioninputwasalreadyused': 'Beschrijving input is al in gebruik',
  'Descriptionisrequired': 'Beschrijving is vereist',
  'DeviceType': 'Apparaattype',
  'DeviceTypeEAM': 'Apparaattype - EAM-naam - EAM-omschrijving',
  'Disabled': 'Uitgeschakeld',
  'Discard': 'Uitsluiten',
  'DisplayRichEditor': 'Toon Rich Editor',
  'Documents': 'Documenten',
  'Download': 'Download',
  'DownloadError': 'Fout bij Downloaden Bestand',
  'DownloadingDocument': 'Document aan het downloaden...',
  'DoyouagreetoDeleteselectedManufacturerModel': 'Ga je akkoord met het wissen van het geselecteerde Fabrikant - Model?',
  'DoyouagreetoDeleteselectedequipment': 'Ga je akkoord met het wissen van het geselecteerde materiaal?',
  'DoyouagreetoRemoveselectedparticipant': 'Ga je akkoord met het verwijderen van de geselecteerde deelnemer?',
  'Draft': 'Concept',
  'DraftCreatedOn': 'Ontwerp aangemaakt op',
  'Dropdown': 'Keuzemenu',
  'DueDate': 'Opleveringsdatum',
  'RequireActionItemDueDateInstruction': 'Indien aangevinkt wordt de Vervaldatum voor actiepunten verplicht',
  'EAMClass': 'EAM-Klasse',
  'EAMCriticality': 'EAM-Kritikaliteit',
  'EAMDepartment': 'EAM-Afdeling',
  'EAMDescription': 'EAM-Omschrijving',
  'EAMName': 'EAM-Naam',
  'EAMType': 'EAM-Type',
  'ENDDEVICESPECIFICABCHECKLISTS': 'AB-CHECKLISTS SPECIFIEK VOOR EINDAPPARAAT',
  'EQUIPMENT': 'MATERIAAL',
  'Edit': 'Bewerk',
  'EditBusinessUnit': 'Bewerk Bedrijfseenheid',
  'EditGroup': 'Bewerk Groep',
  'EditImageError': 'Fout bij Bewerken Afbeelding',
  'EditOrganization': 'Bewerk Organisatie',
  'EditParticipant': 'Bewerk Deelnemer',
  'EditProject': 'Bewerk Project',
  'EditRedLining': 'Bewerk rode lijn',
  'EditSubCategory': 'Bewerk Subcategorie',
  'EditTaskPlanSection': 'Bewerkt Onderdeel Taakplan',
  'EditYellowLining': 'Bewerk gele lijn',
  'Email': 'E-mail',
  'EndDevice': 'Eindapparaat',
  'EndDeviceList': 'Lijst Eindapparaten',
  'EndDeviceTemplate': 'Sjabloon Eindapparaat',
  'EndDevices': 'Eindapparaten',
  'English': 'Engels',
  'EnglishTranslationInProgress': 'Engelse Vertaling in uitvoering',
  'Equipment': 'Materiaal',
  'EquipmentGroup': 'Materiaal Groep',
  'EquipmentList': 'Lijst Materiaal',
  'EquipmentTemplate': 'Sjabloon Materiaal',
  'EquipmentTemplateGroup': 'Sjabloon Materiaal Groep',
  'EquipmentTemplateGroups': 'Sjabloon Materiaal Groepen',
  'EquipmentTemplates': 'Sjablonen Materiaal',
  'EraseLocalData': 'Wis Lokale Gegevens',
  'ErrorImageEditMessage': 'Het was niet mogelijk om de afbeelding te openen om te bewerken',
  'ErrorImageMessage': 'Het was niet mogelijk om de afbeelding te kopiëren',
  'ErrorImageUndoMessage': 'Het was niet mogelijk om de afbeelding ongedaan te maken',
  'EventLabel': 'Label Naam Evenement',
  'EventName': 'Naam Evenement',
  'ExistingNonChevronShared': 'Bestaand Niet-Chevron/Gedeeld',
  'ExportABChecklistsasPDF': 'Exporteer AB-checklists naar PDF',
  'ExportExcel': 'Exporteer Excel',
  'Facility': 'Faciliteit',
  'FacilityHierarchy': 'Faciliteithiërarchie',
  'FacilityNo': 'Faciliteit #',
  'FacilityOperationTeam': 'Faciliteit Operations Team',
  'Factory': 'Fabriek',
  'Featurerequest': 'Functionaliteitsverzoek',
  'Feedback': 'Feedback',
  'FeedbackScreen': 'Feedbackscherm',
  'Fieldisrequired': 'Veld is verplicht',
  'FileDuplicate': 'Het document dat je probeert te uploaden bestaat al. Klik op Annuleren om het uploaden te annuleren of klik op Uploaden om het bestaande document te vervangen',
  'Files': 'Bestanden',
  'FilesUploaded': 'Bestanden zijn succesvol geupload',
  'FilesUploadedWithWarning': 'Sommige bestanden zijn succesvol geupload. Probeer opnieuw.',
  'FillAndSave': 'Vul de velden die als verplicht zijn aangeduid. Klik op Opslaan.',
  'Filter': 'Filter',
  'FilterKMSListbyStatus': 'Filter: KMS-lijst op Status',
  'FilterNumberofKMSItemsreturned': 'Filter: Aantal geretourneerde KMS-punten',
  'FilteredActionItemsInitialWarning': 'Pagina wordt standaard gefiltered op huidige gebruiker en status open',
  'FinalApproval': 'Definitieve Goedkeuring',
  'FinalApprover': 'Definitieve Fiatteur',
  'RequireFinalApproverBeforeInitiationInstruction': 'Indien aangevinkt, heeft de PSSR een Definitieve Fiatteur nodig om dit in gang te zetten',
  'FinalApproverPresent': 'Als de fiatteur aanwezig is, kan de laatste handtekening op het apparaat worden geplaatst; zo niet, dan ter goedkeuring indienen en synchroniseren',
  'FinalApproverSignature': 'Handtekening Definitieve Fiatteur',
  'FoundImages': 'Gevonden Afbeeldingen',
  'FullName': 'Volledige Naam',
  'GENERALINFORMATION': 'ALGEMENE INFORMATIE',
  'GeneralInformation': 'Algemene Informatie',
  'GeneratePSSR': 'Maak {{PSSRLabel}} aan',
  'GetEAMLoadsheet': 'Haal EAM-laadlijst',
  'GetEquipmentList': 'Haal Materiaallijst',
  'GoBack': 'Ga terug',
  'Group': 'Groep',
  'GroupName': 'Groepsnaam',
  'GroupNames': 'Groepsnamen',
  'UserGroups': 'Benutzergruppen',
  'Hi': 'Hi',
  'HideCode': 'Verberg Code',
  'HierarchyLabel1': 'Hiërarchie Label 1',
  'HierarchyLabel2': 'Hiërarchie Label 2',
  'HierarchyLabel3': 'Hiërarchie Label 3',
  'HierarchyLabel3show': 'Hiërarchie Label 3 (toon)',
  'I': 'I',
  'ID': 'ID',
  'Iagree': 'Ik ga akkoord',
  'IagreethatallPSSRchecklistitems': 'Ik verklaar dat alle punten van de PSSR pre-startup veiligheidsevaluatiechecklist en alle actiepunten die nodig zijn voor een veilige opstart, nauwkeurig zijn vastgelegd',
  'Idontagree': 'Ik ga niet akkoord',
  'Image': 'Afbeelding',
  'ImageCaption': 'Bijschrift',
  'ImageCapture': 'Vastlegging afbeelding',
  'Images': 'Afbeelding',
  'ImplementationInProgress': 'Implementatie in uitvoering',
  'ImplementationInProgressEdit': 'Bewerkt Implementatie in uitvoering',
  'ImportSharedUser': 'Importeer Gedeelde Gebruiker',
  'ImportMOCActions': 'Import MOC Action Items',
  'InProgress': 'In uitvoering',
  'Inactive': 'Inactief',
  'IncludeProjectTeam': 'Voeg Projectteamleden toe',
  'Info': 'Info',
  'Initiate': 'Initieer',
  'Initiated': 'Geïnitieerd',
  'InitiatedOn': 'Geïnitieerd',
  'Input': 'Input',
  'InputSharedUserEmail': 'Input e-mail gedeelde gebruiker',
  'InputdescriptionfornewSection': 'Inputbeschrijving voor nieuwe Afdeling',
  'Instructions': 'Instructies',
  'InternalError': 'Interne Fout',
  'IsAdmin': 'Is Admin',
  'IsExternalUser': 'Is Externe Gebruiker',
  'IsSectionOrderEnabled': 'Schakel Aangepaste volgorde voor secties in',
  'ItemCode': 'Code Punt',
  'ItemCodeMsg': '- Code Punt:  <b>',
  'ItemDescriptionMsg': '</b><br/>   - Omschrijving Punt: <b>',
  'Itemwillbetransferredtooperations': 'Punten worden overgebracht naar Operations',
  'Iteration': 'Herhaling',
  'KMSInstruction': 'Zoek op een specifiek MOC Verandermanagementnummer (links) of selecteer in de MOC Verandermanagementlijst Keuzemenu (rechts)',
  'LOCATION': 'LOCATIE',
  'LimitAccessToAIOwner': 'Beperk Toegang tot Eigenaar Actiepunt',
  'LimitAccessToAIOwnerInstruction': 'Indien aangevinkt: 1 - sta Eigenaar Actiepunt/Toegewezen persoon niet toe de omschrijving of vervaldatum van het Actiepunt aan te passen of het Actiepunt te verwijderen. 2 - Leidinggevenden kunnen na goedkeuring alleen bestanden, toegewezen personen, status en opmerkingen toevoegen',
  'AllowTeamLeadToAttach': 'Allow team lead to attach and modify AI',
  'AllowTeamLeadToAttachInstruction': 'If Checked: for Leads after pending approval can only attach files, assignee, status and comment',
  'Links': 'Links ',
  'List': 'Lijst',
  'Load': 'Laad',
  'LoadImages': 'Laad Afbeeldingen',
  'LoadNewQAQC': 'Laad Nieuwe QAQC-punten',
  'Loading': 'Aan het laden',
  'LocaldbID': 'Lokale Database-ID',
  'Location': 'Locatie',
  'LogScreen': 'Logscherm',
  'Login': 'Log in',
  'LoginExpiresOn': 'Inloggen vervalt op',
  'LoginProfile': 'Inlogprofiel',
  'Logout': 'Log uit',
  'LookupbyPasscode': 'Opzoeken met Wachtwoord',
  'Loose': 'Los',
  'MOC': 'MOC Verandermanagement',
  'MOCWO': 'MOC Verandermanagement of Werkorder#',
  'MOCWOProjectNumberRestrictionEnabled': 'MOC Verandermanagement Werkorder Projectnummerbeperking Ingeschakeld',
  'Manage': 'Beheer',
  'ManageContentof': 'Beheer Inhoud van',
  'ManagesEquipments': 'Beheer Materialen',
  'Mandatory': 'Verplicht',
  'Manufacturer': 'Fabrikant - Model',
  'ManufacturerModel': 'Fabrikant - Model',
  'ManufacturerModelfor': 'Fabrikant - Model voor',
  'MarkasClosed': 'Duid aan als Afgesloten',
  'Message': 'Bericht',
  'Milestone': 'QAQC kwaliteitsborgings- en kwaliteitscontrolemijlpaal',
  'MilestoneAndWorkOrders': 'QAQC Werkorder kwaliteitsborgings- en kwaliteitscontrolemijlpaal',
  'MilestoneWorkOrder': 'QAQC Werkorder kwaliteitsborgings- en kwaliteitscontrolemijlpaal',
  'MilestoneWorkOrders': 'QAQC Werkorders kwaliteitsborgings- en kwaliteitscontrolemijlpaal',
  'Milestones': 'QAQC kwaliteitsborgings- en kwaliteitscontrolemijlpalen',
  'MilestonesWorkOrdersNoavailable': 'Geen beschikbare mijlpaal werkorder vanuit QAQC kwaliteitsborging en kwaliteitscontrole',
  'MobileAppVersions': 'Versies Mobiele App',
  'MobileFeature': 'Functionaliteit is alleen beschikbaar op mobiel',
  'MobilePSSR': 'Mobiel PSSR pre-startup veiligheidsevaluatie',
  'Model': 'Model',
  'Multiple': 'Verschillend',
  'MultipleFinalApprovers': 'Indien aangevinkt, staat het de PSSR toe verschillende Definitieve Fiatteurs te hebben',
  'Mustbecompletedbeforetostartup': 'Punt moet voltooid zijn voor het opstarten',
  'NA': 'N.v.t.',
  'Name': 'Naam',
  'NewNonChevron': 'Nieuwe Niet-Chevron',
  'NewOrganization': 'Nieuwe Organisatie',
  'NewPSSR': 'Nieuw PSSR pre-startup veiligheidsevaluatie',
  'NewPSSRCCC': 'Nieuwe PSSR / CCC',
  'NewPSSRCCCORR': 'Nieuwe PSSR / CCC / ORR ',
  'NewPSSRORR': 'Nieuwe PSSR / ORR',
  'NewSectionDescription': 'Nieuwe Beschrijving Afdeling',
  'No': 'Nee',
  'Nomobileappversionsyet': 'Nog geen versies mobiele app',
  'NoActionItems': 'Geen Actiepunten',
  'Nofeedbackyet': 'Nog geen feedback',
  'Nomilestones': 'Geen mijlpalen/werkorders beschikbaar vanuit QAQC kwaliteitsborging en -controle',
  'NonMOC': 'Geen MOC Verandermanagement',
  'NotRegistered': 'Niet geregistreerd',
  'NotVisibleMobile': 'Dit onderdeel is mobiel nog niet zichtbaar tot synchronisatie van gebruikers',
  'Notauthorized': 'Niet geautoriseerd',
  'Note': 'Let op',
  'Notfound': 'Niet gevonden',
  'Notifications': 'Meldingen',
  'OPEN': 'OPEN',
  'OPSignature': 'Operations Handtekening',
  'ORR': 'ORR',
  'OnHold': 'Wordt aangehouden',
  'OnlyFinalApprovercanApprovePSSR': 'Alleen Definitieve Fiatteur kan {{PssrTitle}} goedkeuren',
  'OnlyPSSRTeamLeadClosePSSR': 'Alleen {{PssrTitle}}-teamleider kan PSSR afsluiten.',
  'OnlyPSSRTeamLeadcanClosePSSR': 'Alleen PSSR-teamleider en Definitieve Fiatteur kunnen PSSR afsluiten',
  'OnlyPSSRTeamLeadcanInitiatePSSR': 'Alleen {{PssrTitle}}-teamleider en Definitieve Fiatteur kunnen PSSR initiëren',
  'OnlyPSSRTeamLeadcanSubmitforPendingApproval': 'Alleen {{PssrTitle}}-teamleider kan dit indienen voor Goedkeuring.',
  'OnlyTeamLeadorSectionLeadcananswerthequestions': 'Alleen teamleider of afdelingshoofd kunnen de vragen beantwoorden.',
  'Open': 'Open',
  'OpenStatus': 'Open',
  'OpenError': 'Fout bij Openen Bestand',
  'OpenErrorMsg': 'Het was niet mogelijk het document te openen',
  'OpeningDialog': 'Dialoog aan het openen...',
  'OpeningImage': 'Afbeelding aan het openen...',
  'OperationTeam': 'Operations Team',
  'OperationsRepresentative': 'Vertegenwoordiger Operations',
  'Optional': 'Optioneel',
  'OrgLabel': 'Organisatielabel',
  'Organization': 'Organisatie',
  'OrganizationAdmin': 'Organisatie-Admin',
  'OrganizationLabel': 'Organisatie Label',
  'Organizationisrequired': 'Organisatie is vereist',
  'Other': 'Overig',
  'OtherOptions': 'Overige Opties',
  'OutstandingQuestions': 'Onopgeloste vragen',
  'P3ActionItemsMustBeClosed': 'Actiepunten {{P3Label}} moeten worden afgesloten.',
  'P3Label': 'P3-label',
  'P4ActionItemsMustBeClosed': 'Actiepunten {{P4Label}} moeten worden afgesloten.',
  'P4Label': 'P4-label',
  'P5Label': 'P5-label',
  'PARTICIPANTS': 'DEELNEMERS',
  'PDFPreview': 'PDF Voorbeeld',
  'PIDVerificationRedLining': 'P&ID verificatie leidings- en instrumentatietekening (rode lijn)',
  'PIDVerificationYellowLining': 'P&ID verificatie leidings- en instrumentatietekening (gele lijn)',
  'PROJECTSPECIFICABCHECKLISTS': 'AB-CHECKLISTS SPECIFIEK VOOR PROJECT',
  'PROJECTSTATUS': 'STATUS PROJECT',
  'PSSR': 'PSSR pre-startup veiligheidsevaluatie',
  'PSSRID': 'PSSR ID',
  'PSSRCCC': 'PSSR / CCC',
  'PSSRInitiatorGroup': 'PSSR-initiator Groep',
  'PSSRLabel': 'PSSR pre-startup veiligheidsevaluatielabel',
  'PSSRMembers': 'LEDEN {{PssrTitle}}',
  'PSSRORR': 'PSSR / ORR',
  'PSSRSections': 'PSSR pre-startup veiligheidsevaluatieonderdelen',
  'PSSRStatus': 'Status {{PssrTitle}}',
  'PSSRTeam': 'Team {{PssrTitle}}',
  'PSSRTeamLead': 'Teamleider {{PssrTitle}}',
  'PSSRTemplates': 'PSSR pre-startup veiligheidsevaluatiesjabloon',
  'PSSRTemplateisrequired': 'PSSR pre-startup veiligheidsevaluatiesjabloon is vereist',
  'PSSRTitle': 'Titel PSSR',
  'PSSRType': 'PSSR pre-startup veiligheidsevaluatietype',
  'PSSRTypes': 'PSSR pre-startup veiligheidsevaluatietypes',
  'PSSRmusthaveaFinalApprovertocompleteWalkthrough': '{{PssrTitle}} moet een Definitieve Fiatteru hebben om de Inspectieronde af te ronden',
  'PSSRs': 'PSSRs',
  'NavigateToMainPage': '{{PssrTitle}}s',
  'Page': 'Pagina',
  'Search': 'Suche',
  'Participants': 'Deelnemers',
  'ParticipantsinBlueparticipatedinWalkdown': 'Deelnemers in Blauw hebben deelgenomen aan Inspectieronde',
  'ParticipatedinWalkdown': 'Deelgenomen aan inspectieronde',
  'PendingApproval': 'In afwachting van goedkeuring',
  'PendingApprovalSubmittedOn': 'Ingediend voor Goedkeuring op',
  'Photos': 'Fotos',
  'PickError': 'Fout bij kiezen bestand',
  'PleaseNotify': 'Vraag deelnemers hun mobiele apparaten te synchroniseren voor zij verder gaan met hun activiteiten',
  'PleaseclickSave': 'Klik op Opslaan',
  'Pleasecorrectpageaddress': 'Corrigeer de adrespagina of neem contact op met Support als je meent dat deze url juist is',
  'Pleasefillupthefields': 'Vul de velden in',
  'Pleasefillupthefieldsthataremarkedrequired': 'Vul de velden die als verplicht zijn aangeduid in',
  'PleasegotoSettings': 'Ga naar Instellingen - Meldingen - Mobiele PSSR pre-startup veiligheidsevaluatie om meldingen toe te staan',
  'Pleaseselectanyoption': 'Selecteer elke optie die van toepassing is op het bereik van de wijziging en het sjabloon',
  'Pleaseselectanyoptionthatapplytothescopeofchangeandtemplate': 'Selecteer een optie die past bij het bereik van de wijziging en het sjabloon',
  'Post-WalkdownActionItems': 'Actiepunten na afronding inspectieronde',
  'PostWalkdown': 'Na afloop inspectieronde',
  'PreStartupSafetyReview': 'Pre-startup veiligheidsevaluatie',
  'Present': 'Aanwezig',
  'PrimaryCategory': 'Primaire categorie',
  'Print': 'Druk af',
  'PrinttoPDF': 'Haal naar PDF',
  'Priority': 'Prioriteit',
  'ProcessActivated': 'Proces geactiveerd',
  'Progress': 'Voortgang',
  'Project': 'Project',
  'ProjectName': 'Naam Project',
  'ProjectTeamWillComplete': 'Projectteam vult in na opstart',
  'Projects': 'Projecten',
  'ProjectsList': 'Lijst Projecten',
  'Projectteamwillcompleteafterstartup': 'Punt is compleet na het opstarten',
  'ProvidedBy': 'Verstrekt door',
  'PullFromKMS': 'Haal uit KMS Kennismanagementsysteem',
  'PushNotification': 'Pushbericht',
  'Question': 'Vraag',
  'RESET': 'RESET',
  'ReOpen': 'Open Opnieuw',
  'ReadyToStartUp': 'Klaar voor opstart',
  'ReceivePushNotification': 'Ontvang pushbericht',
  'Receivedfrom': 'Ontvangen van',
  'Recordwillbepermanentlyremovedcontinue': 'Record wordt permanent verwijderd, verder gaan?<br/>',
  'RedirectURL': 'Leid URL door',
  'ReferenceImage': 'Referentieafbeelding',
  'Reject': 'Wijs af',
  'Rejectionreason': 'Reden van afwijzing',
  'ReleaseDate': 'Publicatiedatum',
  'Remove': 'Verwijder',
  'RemoveLocalData': 'Verwijder Lokale Gegevens',
  'RemoveLog': 'Verwijder Logboek',
  'RemoveParticipant': 'Verwijder Deelnemer',
  'RemoveRedLining': 'Verwijder rode lijn',
  'RemoveSection': 'Verwijder Onderdeel',
  'RemoveSignature': 'Verwijder Handtekening',
  'RemoveYellowLining': 'Verwijder gele lijn',
  'RemovingWarning': '<i>Actiepunten</i>, <i>Opmerkingen</i> and <i>Afbeeldingen</i> worden <u>Verwijderd</u> bij deze vraag',
  'Reopen': 'Open opnieuw',
  'RequestAccess': 'Verzoek Toegang',
  'RequestTranslation': 'Verzoek Vertaling',
  'RequestedBy': 'Verzocht door',
  'RequestedOn': 'Verzocht op',
  'Requestedby': 'Verzocht door',
  'RequireActionItemDueDate': 'Vereist Opleverdatum Actiepunt',
  'RequireFinalApproverBeforeInitiation': 'Definitieve fiatteur vereist vóór initiatie',
  'RequiredField': 'Dit veld is verplicht.',
  'RequiresApprovalForAccess': 'Vereist Goedkeuring voor Toegang',
  'RequiresShowProject': '*Vereist dat ‘Toon project’ is ingeschakeld.',
  'ResetABChecklistsforEntireProject': 'Reset AB-checklists voor Gehele Project',
  'ResetABChecklist': 'Reset AB Checklist',
  'ResetData': 'Reset gegevens',
  'ResetSession': 'Reset Sessie',
  'ResolutionImages': 'Resolutie Afbeeldingen',
  'RestorePssr': 'Herstel {{PssrTitle}}',
  'RestrictApproverSignatureOnLeadsDevice': 'Beperk handtekening fiatteur op apparaat leidinggevenden',
  'ReturnTeamLead': 'Terug naar Teamleider',
  'ReviewInProgress': 'Beoordeling in uitvoering',
  'Role': 'Rol',
  'RolesChangeWarning': 'Rollen en veranderingen worden weergegeven na Synchronisatie',
  'Russian': 'Russisch',
  'RussianTranslationRequested': 'Russische Vertaling Aangevraagd',
  'SAVESIGNATURE': 'SLA HANDTEKENING OP',
  'Save': 'Sla op',
  'SaveChanges': 'Sla Wijzigingen op',
  'SaveGoBack': 'Sla op & Ga Terug',
  'SaveImage': 'Sla Afbeelding op',
  'SaveSignature': 'Sla Handtekening op',
  'SavingFile': 'Bestand aan het opslaan',
  'SavingImage': 'Afbeelding aan het opslaan',
  'ScheduledEmail': 'Activeer Geplande E-mail (elke maandag)',
  'Screen': 'Scherm',
  'SearchMOCNumber': 'Zoek MOC-nummer',
  'SecondaryCategory': 'Secundaire categorie',
  'SectionType': 'Type Onderdeel',
  'SectionLead': 'Afdelingshoofd',
  'Sections': 'Onderdelen',
  'ShowSectionsCollapsedInstruction': '*Indien aangevinkt worden PSSR-onderdelen getoont als mislukt bij het bewerken',
  'Select': 'Selecteer',
  'SelectFileError': 'Fout bij selecteren Bestand',
  'SelectFromKMSMOCList': 'Selecteer uit KMS MOC-lijst',
  'SelectLead': 'Selecteer Leidinggevende',
  'SelectOption': 'Selecteer Optie',
  'SelectParticipant': 'Selecteer Deelnemer',
  'SelectResponse': 'Selecteer Reactie',
  'SelectaDrawing': 'Selecteer een Tekening',
  'SelectfromQAQC': 'Selecteer uit QAQC kwaliteitsborging en -controle',
  'Selectpages': 'Selecteer paginas',
  'Selectthepagestoconverttoimages': 'Selecteer de paginas die je wilt converteren naar afbeeldingen',
  'Selectuserfor': 'Selecteer gebruiker voor',
  'SelectUser': 'Wähle den Benutzer',
  'Send': 'Verzend',
  'SendAllNotificationsToApprovers': 'Fiatteurs ontvangen allen PSSR-meldingen',
  'SendEmailReminder': 'Verzend herinnering per e-mail',
  'SendMOCforStartup': 'Send to MOC record for startup approval',
  'SerialNumber': 'Serienummer',
  'SerialNumberRequired': 'Serienummer vereist',
  'Settings': 'Instellingen',
  'Shared': 'Gedeeld',
  'Shipment': 'Zending',
  'ShouldAddCommentToQuestion': 'Opmerking vereist voor antwoord N.V.T.',
  'Show': 'Toon',
  'ShowCCC': 'Toon CCC',
  'ShowDeletedPSSR': 'Toon Geannuleerde {{PssrTitle}}',
  'ShowKMS': 'Toon KMS Kwaliteitsmanagementsysteem',
  'ShowORR': 'Toon ORR Operationele Gereedheidsevalutatie',
  'ShowP5': 'Toon P5',
  'ShowProject': 'Toon Project',
  'ShowProjectInput': 'Toon Projectinput',
  'ShowProjectInputAsDropdown': 'Toon Projectinput als menu',
  'ShowPssrType': 'Toon PSSR pre-startup veiligheidsevaluatietype',
  'ShowQAQC': 'Toon QAQC Kwaliteitsborging en -controle',
  'ShowSectionsCollapsed': 'Toon Mislukte Onderdelen',
  'ShowVerbalApproval': 'Toon Mondelinge Goedkeuring',
  'SignatoryName': 'Naam ondertekenaar',
  'Signature': 'Handtekening',
  'SignatureCapture': 'Leg Handtekening vast',
  'Signatures': 'Handtekeningen',
  'SignedBy': 'Ondertekend door',
  'SignedOnBehalfOf': 'Ondertekend namens',
  'Skidded': 'Geblokkeerd',
  'SortBy': 'Sorteer op',
  'SortChangesApplied': 'Sorteerwijzigingen worden toegepast na het klikken op de knop Opslaan',
  'SortOrder': 'EAM-sorteervolgorde',
  'Startaddingparticipants': 'Begin deelnemers toe te voegen',
  'Started': 'Gestart',
  'Status': 'Status',
  'StatusIsRequired': 'Statusveld is vereist',
  'SubArea': 'Deelgebied',
  'SubCategories': 'Subcategorieën',
  'SubSection': 'Subonderdelen',
  'Subcategories': 'Subcategorieën',
  'Submit': 'Verstuur',
  'SubmitforApproval': 'Verzend voor goedkeuring',
  'Submitforapproval': 'Verzend voor goedkeuring',
  'SubsectionDescription': 'Omschrijving Subonderdeel',
  'Summary': 'Samenvatting',
  'SummarySignature': 'Samenvatting Handtekening',
  'Sync': 'Synchronisatie',
  'SystemAdministrators': 'Systeemadministratoren',
  'TEAMMEMBERS': 'TEAMLEDEN',
  'Tag': 'Tag',
  'TagCode': 'Code Tag',
  'TagNo': 'Nummer Tag',
  'Takeaphoto': 'Neem een foto',
  'Tank': 'Tank',
  'TaskPlanSections': 'Onderdelen Taakplan',
  'TaskPlan': 'Taakplan',
  'TaskPlanNoteHeader': '[Let op]&nbsp;Naam code moet uniek zijn en de naam kan niet gewijzigd worden nadat het Taakplan is aangemaakt',
  'TeamLead': 'Teamleider',
  'Template': 'Sjabloon',
  'Test': 'Test',
  'TestforDeleteDBwithoutApprover': 'Test voor verwijderen Database zonder fiatteur',
  'TheTaskPlanfieldisrequired': 'Het veld Taakplan is verplicht',
  'Thereareexistingitemsusingit': 'Het is in gebruik bij bestaande punten',
  'Therewasanerrorprocessingtryagain': 'Bij het verwerken van je verzoek is een fout opgetreden. Probeer het later opnieuw of neem contact op met de helpdesk.',
  'Thisfieldisrequired': 'Dit veld is verplicht.',
  'Thisisnotareversiblechange': 'Deze wijziging kan niet ongedaan gemaakt worden',
  'TimeCaptured': 'Tijdstip vastgelegd',
  'Title': 'Titel',
  'Titleisrequired': 'Titel is vereist',
  'TotalQuestions': 'Totaal Vragen',
  'TranslationCompleted': 'Vertaling Afgerond',
  'TranslationHistory': 'Geschiedenis Vertalingen',
  'TranslationInProgress': 'Vertaling in uitvoering',
  'TranslationRequested': 'Vertaling aangevraagd',
  'Translator': 'Vertaler',
  'Type': 'Type',
  'TypeOtherisonlyfornon': 'Type "Overig" is alleen voor niet-Chevrongebruikers, gebruik van dit type kan leiden tot dubbele gebruikersgegevens',
  'Undo': 'Maak ongedaan',
  'UndoImageError': 'Fout bij Ongedaan maken Afbeelding',
  'Unit': 'Eenheid',
  'Update': 'Werk bij',
  'UpdatedBy': 'Bijgewerkt door',
  'UpdatedByUpdatedDate': 'Bijgewerkt door/Bijgewerkt op',
  'UpdatedDate': 'Bijgewerkte datum',
  'Upload': 'Upload',
  'UploadDocError': 'Fout bij uploaden document',
  'UploadDocErrorMsg': 'Het was niet mogelijk het document op te slaan',
  'UploadDocument': 'Upload Document',
  'UploadDocuments': 'Upload Documenten',
  'UploadImages': 'Upload Afbeeldingen',
  'UploadMsg': 'Nieuw document werd opgeslagen',
  'Uploadaphoto': 'Upload een foto',
  'User': 'Gebruiker',
  'User1': 'Gebruiker 1',
  'User2': 'Gebruiker 2',
  'User3': 'Gebruiker 3',
  'User4': 'Gebruiker 4',
  'UserAlreadyAdded': 'Gebruiker reeds toegevoegd',
  'UserType': 'Type gebruiker',
  'Users': 'Gebruikers',
  'Usersavedsuccessfully': 'Gebruiker succesvol opgeslagen',
  'Vendor': 'Leverancier',
  'VerbalApproval': 'Mondelinge Goedkeuring',
  'VerbalApprovalReceivedBy': 'Mondelinge goedkeuring ontvangen door',
  'VerbalApprovalforStartupOn': 'Mondelinge Goedkeuring voor Opstart op',
  'Version': 'Versie',
  'ViewImage': 'Bekijk Afbeelding',
  'ViewItems': 'Bekijk Punten',
  'WO': 'WO Werkorder',
  'WTNotPresentParticipantsWarning': 'De volgende deelnemers zijn niet aangemerkt als aanwezig',
  'WTParticipantsWithoutSignatureWarning': 'Bij de volgende deelnemers staat geen handtekening',
  'WTRestrictParticipantsModificationWarning': 'Na Voltooide inspectieronde kunnen deelnemers niet meer gewijzigd worden',
  'Walkdown': 'Inspectieronde',
  'WalkdownSignature': 'Handtekening Inspectieronde',
  'WalkdownComplete': 'Inspectieronde Afgerond',
  'WalkdownCompletedOn': 'Inspectieronde Afgerond op',
  'Warning': 'Waarschuwing',
  'WebLink': 'Weblink',
  'WorkOrder': 'QAQC Werkorder kwaliteitsborging en -controle',
  'WorkOrders': 'QAQC Werkorders kwaliteitsborging en -controle',
  'WorkOrdersNoavailable': 'Geen mijlpalen/werkorders beschikbaar vanuit QAQC kwaliteitsborging en -controle',
  'XCoordinate': 'X-coördinaat',
  'YCoordinate': 'Y-coördinaat',
  'Yes': 'Ja',
  'Youareabouttologout': 'Je staat op het punt om uit te loggen; hierdoor worden de lokale gegevens op dit apparaat verwijderd, wil je doorgaan?',
  'Youareabouttorequestaccessfor': 'Je staat op het punt om toegang aante vragen voor <strong>{{Name}}</strong>, wil je verder gaan?',
  'Youareloggedinas': 'Je bent ingelogd als',
  'Youdonothaveaccesstothispage': 'Je hebt geen toegang tot deze pagina',
  'Yourprofile': 'Je profiel',
  'approvethisPSSRreadyforstartup': 'keur deze PSSR pre-startup veiligheidsevaluatie goed voor opstarten',
  'cannotberemoved': 'kan niet worden verwijderd',
  'cost255': 'Kostencode (max 255 tekens)',
  'desc5000': 'Voeg hier omschrijving toe (max 5000 tekens)',
  'description1920': 'Voeg hier omschrijving toe (max 1920 tekens)',
  'description500': 'Voeg hier omschrijving toe (max 500 tekens)',
  'device150': 'Naam Type Apparaat (max 150 tekens)',
  'donothaveaccesstothePSSRapplication': 'geen toegang tot de PSSR pre-startup veiligheidsevaluatietoepassing',
  'eam50': 'EAM-type (max 50 tekens)',
  'eamclass80': 'EAM-categorie (max 80 tekens)',
  'eamcritical50': 'EAM-kritikaliteit (max 50 tekens)',
  'eamdep255': 'EAM-afdeling (max 255 tekens)',
  'eamdesc80': 'EAM-omschrijving (max 80 tekens)',
  'eamname80': 'EAM-naam (max 80 tekens)',
  'fromTaskPlan': 'van Taakplan',
  'fromTaskPlanSection': 'van Onderdeel Taakplan',
  'group155': 'Naam Groep (max 155 tekens)',
  'isrequired': 'is verplicht',
  'list': 'lijst',
  'manufacturer250': 'Naam Fabrikant (max 250 tekens)',
  'model250': 'Model (max 250 tekens)',
  'noticetousers': 'Melding voor Gebruikers',
  'of10': 'van 10',
  'of150': 'van 150',
  'of155': 'van 155',
  'of250': 'van 250',
  'of255': 'van 255',
  'of50': 'van 50',
  'of500': 'van 500',
  'of80': 'van 80',
  'optional': 'optioneel',
  'select1milestone': 'Selecteer 1 mijlpaal om de werkorders binnen die mijlpaal te zien',
  'tagcode10': 'Tag-code (max 10 tekens)',
  'tagno10': 'Tag-nummer (max 10 tekens)',
  'typeemail': 'type e-mail nieuwe gebruiker...',
  'typelastname': 'type achternaam nieuwe gebruiker...',
  'typenewuser': 'type nieuwe gebruikersnaam...',
  'typeusercainame': 'type cai, naam of e-mail Chevron-gebruiker...',
  'willberemoved': 'wordt verwijderd',
  'willbeungrouped': 'Als bestaande Materialen aan deze Groep zijn toegevoegd, worden deze uit de groep verwijderd',
  'x255': 'X-coördinate (max 255 tekens)',
  'y255': 'Y-coördinaat (max 255 tekens)',
  'yourAccessRequestisinProgress': 'je Verzoek voor Toegang is in behandeling',
  'youwanttoproceed': 'je wilt verder gaan',
  'Unabletocompleteoperation': 'De handeling kan niet voltooid worden',
  'SaveCompleted': 'Opslaan afgerond',
  'Itemhasbeenaddedsuccessfully': 'Punt is succesvol toegevoegd',
  'ShowProjectInputInstruction': '*Toont projectmenu/-input',
  'ShowQAQCInstruction': '*Schakelt QAQC-module in bij projectmodule. Toon Project moet eerst ingeschakeld worden.',
  'ShowKMSInstruction': '*Toont "Haal uit KMS"-knop wanneer je een nieuw PSSR aanmaakt',
  'AllowWetSignatureInstruction': '*Staat de Vertegenwoordiger van Operations toe de PSSR goed te keuren bij status "Goedkeuring in aanvraag". Toont knop "Keur goed met fysieke handtekening"',
  'ShowVerbalApprovalInstruction': '*Toont input in de mobiele app om handmatig te schrijven wie de goedkeuring geeft. Dit heeft geen actie tot gevolg. Kan worden uitgevoerd door Leidinggevende of Fiatteur.',
  'ScheduledEmailInstruction': '*Elke maandag om 8:00 uur CST wordt een e-mail gezonden aan alle gebruikers met openstaande actiepunten',
  'ShowCCCInstruction': '*Toont "CCC" bij bepaalde kopjes/titels/labels (Construction Complete Certificate)',
  'ShowORRInstruction': '*Toont "ORR" bij bepaalde kopjes/titels/labels (Operations Readiness Review)',
  'ShowPssrTypeInstruction': '*Toont "PSSR Types"-menu (hieronder)',
  'AllowNAResponsesInstruction': '*Indien uitgeschakeld: een vraag bij PSSR die met N.V.T. is beantwoord vereist een opmerking of actie om als afgerond te worden beschouwd.',
  'PssrInstructionsInstruction': '*Toont algemene instructies voor de PSSR',
  'AllowLeadsDeleteQuestionsInstruction': '*Stelt Leidinggevende in staat vragen te wissen voor een PSSR met de status Ontwerp of Geïnitieerd.',
  'EnableAllowMembersCompletePSSRInstruction': ' *Stelt elke deelnemer in staat een PSSR te voltooien voordat deze Afgerond is (vragen beantwoorden, vragen ondertekenen, aanmaken actiepunten)',
  'MOCWOProjectNumberRestrictionEnabledInstruction': '*Indien Ingeschakeld: MOC en WO zijn vereist om een PSSR aan te maken',
  'AllowMultiApproverInstruction': '*Indien Ingeschakeld: PSSR kan meer dan een Fiatteur hebben',
  'RestrictApproverSignatureOnLeadsDeviceInstruction': '*Indien Ingeschakeld: PSSR-leidinggevend kan niet namens de Fiatteur tekenen in de mobiele app',
  'AllowLeadtoShareDeviceWithApprover': 'Allow Lead to Share Device With Approver',
  'AllowLeadtoShareDeviceWithApproverInstruction': '*If Enabled: The Lead can share the device with the Approver if the Approver is present to sign.',
  'RequiresApprovalForAccessInstruction': '*Indien Ingeschakeld: nieuwe gebruiker moet toegang vragen en wachten tot BU Admin toegang verleent',
  'AllowApproverToSubmitForApprovalInstruction': '*Indien aangevinked kan de Definitieve Fiatteur de "Indienen voor Goedkeuring"-knop zien in PSSR',
  'OrganizationCannotBeEmpty': '{{OrganizationLabel}} kan niet leeg zijn',
  'OrganizationSuccessfullyAdded': '{{OrganizationLabel}} is succesvol toegevoegd',
  'MOCImportedSuccessfully': 'MOC succesvol geïmporteerd',
  'GeneralInformationSavedSuccessfully': 'Algemene Informatie succesvol opgeslagen',
  'Therecordenteredalreadyexists': 'Het ingevoerde record bestaat al',
  'SelectuserforTranslationRole': 'Selecteer gebruiker voor Rol Vertaling',
  'TranslatetoLanguage': 'Vertaal naar Taal',
  'Selecttranslator': 'Selecteer vertaler',
  'PresentParticipantsInstruction': 'Deelnemers in <span class="box participated">&nbsp;Blue&nbsp;</span> hebben deelgenomen aan de Inspectieronde',
  'PssrParticipantAddedSuccessMessage': 'Gebruiker succesvol toegevoegd aan PSSR-deelnemers',
  'RoleAlreadyAddedWarningMessage': '{{RoleLabel}} Rol is al toegevoegd',
  'MaxParticipantsPerBatchInstruction': 'Er kunnen slechts 5 gebruikers per keer toegevoegd worden.',
  'MaxParticipantsPerBatchWarningMessage': 'Limiet van 5 gebruikers is bereikt, selecter Voeg toe',
  'ParticipantAlreadyParticipatedWarningMessage': 'Deze gebruiker is al een lid van deze PSSR',
  'ManageSectionUsersSyncWarning': 'Dit onderdeel is nog niet zichtbaar op mobiele apparaten tot de gebruikers synchroniseren. Vraag deelnemers hun mobiele apparaten te synchroniseren voor zij verder gaan met hun activiteiten.',
  'NotFoundComponentTitle': 'Niet gevonden',
  'NotFoundComponentDescription': 'Wijzig het pagina-adres of neem contact op met Support als je meent dat deze url juist is',
  'UnauthorizedTitle': 'Niet geautoriseerd',
  'UnauthorizedDescription': 'Je hebt geen toegang tot deze pagina',
  'BUisrequired': 'BU is vereist',
  'SectionLeadWasAlreadyAddedWarningMessage': '{{UserName}} is al een leidinggevende voor dit onderdeel',
  'SectionLeadAddedErrorMessage': 'Bij het toevoegen van de gebruiker is een fout opgetreden.',
  'SectionLeadRemovedErrorMessage': 'Bij het verwijderen van de gebruiker is een fout opgetreden.',
  'AddResponseErrorMessage': 'Bij het aanmaken van een reactievraag is een fout opgetreden.',
  'ConfirmRemoveSectionTitle': 'Verwijder Onderdeel',
  'ConfirmRemoveSectionDescription': 'Weet je zeker dat je deze actie wilt uitvoeren?',
  'MilestoneWorkOrdersWarningMessage': 'Selecteer 1 mijlpaal om de werkorders binnen die mijlpaal te zien',
  'QAQCAddSectionLeadToPssr': 'Wijzig afdelingshoofden toe',
  'LoadBusinessUnitsErrorMessage': 'Fout bij ophalen Business Units',
  'LoadOrganizationsErrorMessage': 'Fout bij ophalen Organisaties',
  'AddProjectErrorMessage': 'Fout bij toevoegen nieuw {{EventLabel}}',
  'AddProjectSuccessMessage': 'Project succesvol toegevoegd',
  'LoadQAQCErrorMessage': 'Fout bij het ophalen van {{EventLabel}} vanuit QAQC',
  'SaveQAQCAlreadyExistsWarningMessage': '{{EventLabel}} bestaat al',
  'SaveQAQCSuccessMessage': '{{EventLabel}} succesvol toegevoegd',
  'CompletedTotalPSSRs': 'Voltooid/Totaal PSSRs',
  'ProjectUpdatedSucessfully': 'Project succesvol bijgewerkt',
  'ConfirmDeleteProjectTitle': 'Bevestig verwijderen Project',
  'ConfirmDeleteProjectWithPssrsDescription': 'De volgende actie zou het Project en de bijbehorende PSSRs kunnen verwijderen. Wil je verder gaan?',
  'ConfirmDeleteProjectWithoutPssrsDescription': 'Wil je verder gaan?',
  'ConfirmInitiateProjectPssrsTitle': 'Initieer PSSRs',
  'ConfirmInitiateProjectPssrsDescription': 'Wil je Alle PSSRs initiëren?',
  'UnableLoadQAQCMilestonesMessage': 'qaqc-systeem kan niet worden bereikt voor mijlpalen, probeer het later nog eens',
  'UnableLoadQAQCWorkOrdersMessage': 'qaqc-systeem kan niet worden bereikt voor werkorders, probeer het later nog eens',
  'LoadProjectDetailsErrorMessage': 'Fout bij laden projectgegevens',
  'QAQCItemsAreUpToDateMessage': 'QAQC-punten zijn bijgewerkt',
  'QAQCNewItemInputWarning': 'Selecteer een punt uit de lijst {{qaqcLabel}} en selecteer de hiërarchiewaardes en/of -sjabloon',
  'ConfirmCloseProjectTitle': 'Sluit het Project af',
  'ConfirmCloseProjectDescription': 'Wil je dit project afsluiten?',
  'DownloadActionItemsExcelReportFailureMessage': 'Het downloaden van het Excel-bestand is niet gelukt',
  'ConfirmDeleteAreaTitle': 'Weet je zeker dat je {{hierarchyLabel1}} wilt verwijderen?',
  'ConfirmDeleteAreaDescription': 'Alle betrokken {{ hierarchyLabel2 }} & {{ hierarchyLabel3 }} worden verwijderd.',
  'ConfirmDeleteSubAreaTitle': 'Weet je zeker dat je {{ hierarchyLabel2 }} wilt verwijderen?',
  'ConfirmDeleteSubAreaDescription': 'Alle betrokken {{ hierarchyLabel3 }} worden verwijderd',
  'NewGroupadded': 'Nieuwe Groep toegevoegd',
  'RequiredFields': 'Vereist Velden',
  'AddedUserSuccessfulMessage': 'Gebruiker succesvol toegevoegd',
  'DeletedUserSuccessfulMessage': 'Gebruiker succesvol verwijderd',
  'EditedGroupSucessfulMessage': 'Groep succesvol bijgewerkt',
  'Userisrequired': 'Gebruiker is vereist',
  'FirstNameisrequired': 'Voornaam is vereist',
  'SharedUserNameisrequired': 'Gedeelde Gebruikersnaam is vereist',
  'Emailisrequired': 'E-mail is vereist',
  'User1isrequired': 'Gebruiker 1 is vereist',
  'Usertypeiswrong': 'Type Gebruiker is onjuist',
  'RepeatedEmailsInputWarning': 'Deze e-mails worden herhaald: [{{repeatedEmails}}}]<br/>',
  'Shareduserfound': 'Gedeelde gebruiker gevonden',
  'AddRemoveSubcategory': 'Verwijder/Voeg Subcategorie toe',
  'CreateNewCategory': 'Maak Nieuwe Categorie aan',
  'IsActive': 'Is Actief',
  'EditCategory': 'Bewerk Categorie',
  'CommentisRequired': 'Opmerking is vereist',
  'RejectreasonisRequired': 'Reden afwijziging is vereist<br>',
  'Duedateisrequired': 'Vervaldatum is vereist',
  'IAgree': 'Ik ga akkoord',
  'All': 'Alle',
  'CreateFeedback': 'Maak Feedback aan',
  'Enhancement': 'Verbetering',
  'Environment': 'Omgeving',
  'Web': 'Web',
  'MobileWeb': 'Mobiel & Web',
  'Mobile': 'Mobiel',
  'High': 'Hoog',
  'Medium': 'Medium',
  'Low': 'Laag',
  'FeedbackSuccessfullyadded': 'Feedback succesvol toegevoegd',
  'EditFeedback': 'Bewerkt Feedback',
  'Typetoaddanewdiscussioncomment': 'Type om een nieuwe discussieopmerking toe te voegen',
  'Attachments': 'Bijlagen',
  'ADOStatus': 'ADO-status',
  'FeedbackUpdated': 'Feedback bijgewerkt',
  'UpdatedFeedbacksAttachmentErrorMessage': 'Bij de bestanden is een fout opgetreden. Check of je bestanden niet beschadigd of te groot zijn',
  'LoadingKMSItemsErrorMessage': 'Bij het zoeken naar KMS-punten is een fout opgetreden',
  'TaskPlanManageTitle': 'Beheer inhoud van {{ Description }}',
  'CreateNewSection': 'Maak Nieuw Onderdeel aan',
  'NewSubsection': 'Nieuw Subonderdeel',
  'ItemType': 'Type Punt',
  'ConfirmDeleteTaskPlanSectionMessage': 'Weet je zeker dat je <span style="color: red">{{ taskPlanSectionDesc }}</span> <br />  wilt verwijderen uit Taakplan {{ taskPlanDesc }}?',
  'ConfirmDeleteTaskPlanSubSectionMessage': 'Weet je zeker dat je <span style="color:red">{{subSection}}</span> <br> uit Taakplan Onderdeel <b>{{section}}</b> wilt verwijderen?',
  'EditTaskPlanSubSection': 'Bewerk Taakplan Subonderdeel',
  'RequestforComments': 'Vraag om Opmerkingen',
  'RequestforActionItems': 'Vraag om Actiepunten',
  'PSSRInitializedSuccessfully': 'PSSR Initialized Successfully',
  'PrimeNgCalendarLabels': {
    'firstDayOfWeek': 0,
    'dayNames': [
      'zondag',
      'maandag',
      'dinsdag',
      'woensdag',
      'donderdag',
      'vrijdag',
      'zaterdag'
    ],
    'dayNamesShort': [
      'zo',
      'ma',
      'di',
      'wo',
      'do',
      'vr',
      'za'
    ],
    'dayNamesMin': [
      'Zo',
      'Ma',
      'Di',
      'Wo',
      'Do',
      'Vr',
      'Za'
    ],
    'monthNames': [
      'januari',
      'februari',
      'maart',
      'april',
      'mei',
      'juni',
      'juli',
      'augustus',
      'september',
      'oktober',
      'november',
      'december'
    ],
    'monthNamesShort': [
      'Jan',
      'Feb',
      'Maa',
      'Apr',
      'Mei',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Okt',
      'Nov',
      'Dec'
    ],
    'today': 'Today',
    'clear': 'Clear',
    'dateFormat': 'mm/dd/yy',
    'weekHeader': 'Wk'
  },
  'Adminmenu': 'Admin menu',
  'Organizations': 'Organizations',
  'UserGuide': 'User Guide',
  'Administration': 'Administration',
  'ProjectSpecific': 'Project Specific',
  'Showdeletedprojects': 'Show deleted projects',
  'ProjectManagers': 'Project Managers',
  'ProjectTeamMembers': 'Project Team Members',
  'Activate': 'Activate',
  'AddActionItem': 'Add Action Item',
  'Attention': 'Attention',
  'BacktoCategoryList': 'Back to Category List',
  'Category': 'Category',
  'CompleteTranslationWarning': 'Please translate PSSR Title, Description, Action Item Title and Action Item Comments',
  'ConfirmTranslationRequest': 'You are about to request a translation in {{language}} to {{email}}',
  'DeselectAll': 'Deselect All',
  'FirstName': 'First Name',
  'Itemsselected': 'Items selected',
  'Language': 'Language',
  'LastName': 'Last Name',
  'MOC#': 'MOC #',
  'New': 'New',
  'Org': 'Org',
  'ShowDeletedPSSRCCC': 'Show Deleted PSSR / CCC',
  'ShowDeletedPSSRCCCORR': 'Show Deleted PSSR / CCC / ORR',
  'ShowDeletedPSSRORR': 'Show Deleted PSSR / ORR',
  'Togglenavigation': 'Toggle navigation',
  'Youshouldnothaveemptysections': 'You should not have empty sections',
  'togglesearch': 'toggle search',
  'GenericErrorTitle': 'Interne Fout',
  'GenericErrorDescription': 'Er trad een fout op bij het verwerken van je verzoek. Probeer het later nog eens of neem contact op met de helpdesk.',
  'Kazakh': 'Kazachs',
  'PSSRmusthaveaFinalApprovertocompleteWalkdown': 'PSSR must have a Final Approver to complete Walkdown',
  'ParticipatedinWalkthrough': 'Participated in Walkthrough',
  'Subsection': 'Sub section',
  'AccessUntilApprove': 'Access Until Approve',
  'AllNotifications': 'All Notifications',
  'AreyousureyouwanttoSubCategory': 'Are you sure you want to Sub Category',
  'DueDateMandatory': 'Due Date Mandatory',
  'FinalApproverSubmit': 'Final Approver Submit',
  'FinalApproverInitiate': 'Final Approver Initiate',
  'SectionsCollapsed': 'Sections Collapsed',
  'Portuguese': 'Portugees',
  'Dutch': 'Dutch',
  'SysAdmin': 'Sys Admin',
  'ChevronDisclaimer': 'This Chevron software product is for use by authorized users only. To the extent permitted by law, by\n  using this software product you acknowledge and consent to the monitoring, access (including the\n  decryption and inspection of selected encrypted internet\n  traffic), use or disclosure by Chevron of any information generated, received or stored on this\n  system/device and waive any right or expectation of privacy in connection with your use. Unauthorized\n  and/or improper use of this software\n  product in violation of Chevron corporate policies will be not tolerated and may result in disciplinary\n  action, including termination.',
  'ConfirmDeletePSSRTitle': 'Bevestig verwijderen',
  'ConfirmDeletePSSRMessage': 'PSSR wordt definitief verwijderd, doorgaan?<br/>',
  'ConfirmNewTrainingTitle': 'Confirm new PSSR Training',
  'ConfirmNewTrainingMessage': 'New Training PSSRs will be created.<br/> Do you want to continue?<br/>',
  'ConfirmDeleteActionItemMessage': 'Weet je zeker dat je deze actie wilt uitvoeren?',
  'CannotCloseProjectDueOpenPSSRs': 'Kan het project niet sluiten omdat er open PSSRs zijn',
  'PSSRIdisrequired': 'PSSR-ID is vereist',
  'ImportFromisrequired': 'Importeren van is vereist.',
  'MOCIDisalreadypresentintheImportlist': 'MOC ID is al aanwezig in de importlijst.',
  'ErrorhappenedwhileimportingPSSR': 'Er is een fout opgetreden tijdens het importeren van PSSR',
  'RestoreProject': 'Herstel Project',
  'Spanish': 'Spanish',
  'ImportPSSRs': 'Importeren PSSRs',
  'Import': 'Importeren',
  'Application': 'Sollicitatie',
  'Records': 'Records',
  'Imported': 'Geïmporteerd',
  'Errors': 'Fouten',
  'ImportAll': 'Importeren Allemaal',
  'QAQCImport': 'QAQC Import',
  'Start': 'Start PSSR',
  'ePSSRServiceNowTicketingInstructions': 'ePSSR Service Now-ticketinstructies',
  'ReportanIssue': 'Een probleem melden',
  'EnhancementRequest': 'Verbeteringsverzoek',
  'UserAccessRequest': 'User Access Request',
  'ApplicationSupportRequestForm': 'Ga naar het <a href="https://chevron.service-now.com/itserviceportal?id=sc_cat_item_guide&sys_id=9c08a21edb126450182e1dca48961981" target="_blank">aanvraagformulier voor aanvraagondersteuning</a> in het Chevron IT-serviceportaal`',
  'UndertheApplicationFieldselectMobilePSSR': 'Selecteer onder het toepassingsveld <b>Mobile PSSR</b>.',
  'ToreportanissueorbugpleaseselectReportanIssueTosubmitanenhancementrequestoruseraccessrequestpleaseselectServiceRequestHitNext': 'Selecteer <b>Een probleem melden</b> om een ​​verbeteringsverzoek of gebruiker in te dienen om een ​​probleem of bug te meldentoegangsverzoek selecteer <b>Serviceverzoek</b>. Druk op Volgende..',
  'FillouttheDescriptionoftheProblemImpactandUrgency': 'Vul de Beschrijving van het probleem, de impact en de urgentie in.',
  'PressNextandthenSubmit': 'Druk op Volgende en vervolgens op Verzenden.',
  'TheePSSRSupportTeamwillacknowledgethereceiptoftheticketin24hoursduringbusinesshours': 'Het ePSSR-ondersteuningsteam bevestigt de ontvangst van het ticket binnen 24 uur tijdens kantooruren.',
  'PleaseselectEnhancementsunderTypeofRequests': 'Selecteer <b>Verbeteringen</b> onder Soort verzoek.',
  'SelectPriorityLowModerateHigh': 'Prioriteit selecteren <b>(laag, matig, hoog)</b>',
  'FillouttheDescriptionofRequest': 'Vul de omschrijving van de aanvraag in',
  'SelectNextandSubmit': 'Selecteer Volgende en Verzenden.',
  'TheePSSRSupportTeamwillreviewenhancementrequestsaspartoftheSurfaceDigitalPIPlanningProcess': 'Het ePSSR-ondersteuningsteam zal verbeteringsverzoeken beoordelen als onderdeel van het Surface Digital PI-planningsproces',
  'PleaseselectRequestAccessunderTypeofRequests': 'Selecteer Toegang aanvragen onder Type verzoeken.',
  'SelectAccessRequestTypeAddorRemove': 'Selecteer type toegangsverzoek <b>(toevoegen of verwijderen)</b>',
  'SpecifyRoleandAccesstheUserneeds': 'Geef de rol en toegang op die de gebruiker nodig heeft.',
  'UserAlreadyPartipatedInPssr': 'Participant ["{{newTranslator}}"] has already participated in pssr',
  'TranslateActionItem': 'Translate Action Item',
  'AbandonedPSSRInterval': 'Stagnant PSSR Interval',
  'ShowOnlyDeletedPSSRs': 'Show Only Deleted PSSRs',
  'ShowOnlyAbandonedPSSRs': 'Show only Stagnant PSSRs',
  'CloseConfirmation': 'Remember, by closing the PSSR, you confirm that all action items have been closed. Close PSSR?',
  'ReimportAllSharedUsers': 'Re-import All Shared Users',
  'ImportSharedUsersFailed': 'Import failed',
  'ImportSharedUsersIncompleted': 'Import incomplete',
  'ImportSharedUsersCompleted': 'Import complete in: {{ minutes }}mins',
  'ConfirmReimportAllSharedUsersHeader': 'Re-import All Shared Users',
  'ConfirmReimportAllSharedUsersMessage': 'You are about to update all shared users configuration.<br> This operation could take several minutes to complete.',
  'SendaremindertoAssignees': 'Send a reminder to Assignees',
  'SendReminderInstructions': 'Send reminder to assignees for all opened AIs.\nWhen PSSR is in Pending Approval status, \nApprovers will receive notification.',
  'Sendamessage': 'Send a message',
  'SendanemailmessagetoallPSSRParticipants': 'Send an email message to all PSSR Participants',
  'ImportSharedUserOptionWhitePagesTitle': 'White Pages Integration',
  'ImportSharedUserOptionWhitePagesDescription': 'Use this type of shared email if you need to sync with White Pages. The list of users will be updated automatically over time. Some users may be automatically added or removed from this shared email.',
  'ImportSharedUserOptionCustomTitle': 'Custom Shared Email',
  'ImportSharedUserOptionCustomDescription': 'Manually manage the list of users.',
  'ImportActionItemsSuccessfully': 'Imported Action Items successfully',
  'ImportActionItemsNone': 'No Action Items to import',
  'FR.Titles.FunctionalReview': 'Functionele beoordeling',
  'FR.Actions.CompleteReview': 'Complete Review',
  'FR.Warnings.NotFoundFunctionalReview': 'There is no active functional review for selected filters. To start new functional review click create button.',
  'FR.Warnings.CannotStartFunctionalReview': 'Please select an asset and review type to start a functional review',
  'FR.Titles.ChecklistSections': 'Onderdelen Taakplan',
  'FR.Titles.CreateNewSection': 'Maak Nieuw Onderdeel aan',
  'FR.Titles.CreateNewSubSection': 'Create new sub section',
  'FR.Titles.ManageChecklist': 'Beheer inhoud van {{ Description }}',
  'FR.Forms.Labels.ReviewType': 'Type recensie',
  'FR.Forms.Labels.Description': 'Beschrijving',
  'FR.Tables.Headers.ID': 'ID',
  'FR.Tables.Headers.Priority': 'Prioriteit',
  'FR.Tables.Headers.Action': 'Actie',
  'FR.Tables.Headers.Comment': 'Opmerking',
  'FR.Tables.Headers.UpdatedBy': 'Bijgewerkt door',
  'FR.Tables.Headers.Answer': 'Antwoord',
  'FR.Tables.Headers.Question': 'Vraag',
  'FR.Tables.Headers.Code': 'Code',
  'FR.Tables.Headers.ReviewType': 'Type recensie',
  'FR.Tables.Headers.Description': 'Beschrijving',
  'FR.Actions.CreateNewItem': 'Maak Nieuw Punt aan',
  'FR.Actions.BackToChecklists': 'Back to Checklists',
  'FR.Titles.CreateChecklistItem': 'Create a Checklist Item',
  'FR.Actions.BackToChecklistsManage': 'Back to Checklist Manage',
  'FR.Titles.EditChecklistItem': 'Edit Checklist Item',
  'FR.Titles.ConfigureActionItem': 'Configureer Actiepunt',
  'FR.Titles.ConfigureComment': 'Configure Comment',
  'FR.Forms.Labels.Action': 'Actie',
  'FR.Forms.Labels.SubSection': 'Subonderdelen',
  'FR.Forms.Labels.Comment': 'Opmerking',
  'FR.Forms.Labels.Priority': 'Prioriteit',
  'FR.Forms.Errors.InputRequired': 'Dit veld is verplicht.',
  'FR.Forms.Errors.RepeatedReviewType': 'This review type has already been taken',
  'FR.Forms.Errors.ReviewTypeMaxLength': 'Review Type should be {{maxlength?.requiredLength}} characters only',
  'FR.Titles.CreateChecklist': 'Create a Checklist',
  'FR.SubTitles.ReviewTypeWarning': '[Note]:&nbsp;Review Type needs to be unique, and it cannot be renamed after Checklist is created.',
  'FR.Errors.UnavailableMOC': 'Please use the MOC tool to access the Functional Review.',
  'FR.Errors.BuAssetNotSelected': 'Please select a Business Unit and Asset',
  'FR.Errors.ReviewError': 'An error occurred while completing the review',
  'FR.Forms.Labels.SectionDescription': 'Beschrijving Afdeling',
  'FR.Forms.Labels.SubsectionDescription': 'Omschrijving Subonderdeel',
  'FR.Messages.CommentAdded': 'Comment added!',
  'FR.Messages.CommentUpdated': 'Comment updated!',
  'FR.Messages.CommentDeleted': 'Comment deleted!',
  'FR.Messages.ActionItemAdded': 'Action item added!',
  'FR.Messages.ActionItemUpdated': 'Action item updated!',
  'FR.Messages.ActionItemDeleted': 'Action item deleted!',
  'FR.Messages.ResponseAdded': 'Response added!',
  'FR.Messages.ResponseAnswered': 'Response answered!',
  'FR.Messages.ResponseRemoved': 'Response removed!',
  'FR.Messages.ChecklistCreated': 'Check list created',
  'FR.Messages.ChecklistUpdated': 'Check list updated',
  'FR.Messages.ChecklistSectionUpdated': 'Check list section updated',
  'FR.Messages.ChecklistSubSectionUpdated': 'Check list sub section updated',
  'FR.Messages.ChecklistItemCreated': 'Check list item created',
  'FR.Messages.ChecklistItemUpdated': 'Check list item updated',
  'FR.Actions.Add': 'Voeg toe',
  'FR.Actions.Create': 'Maak aan',
  'FR.Actions.Save': 'Sla op',
  'FR.Actions.Cancel': 'Annuleer',
  'FR.Messages.ReviewCompleted': 'Review completed successfully',
  'FR.Dialogs.Actions.Yes': 'Ja',
  'FR.Dialogs.Actions.No': 'Nee',
  'FR.Dialogs.Title.Delete': 'Bevestig verwijderen',
  'FR.Dialogs.Description.Delete': 'Wil je verder gaan?',
  'FR.Titles.EditChecklistSection': 'Edit a Checklist Section',
  'FR.Titles.EditChecklistSubSection': 'Edit a Checklist Subsection',
  'FR.Forms.Labels.Code': 'Code',
  'FR.Forms.Labels.CreatedBy': 'Aangemaakt door',
  'FR.Forms.Labels.UpdatedDate': 'Bijgewerkte datum',
  'FR.Forms.Labels.UpdatedBy': 'Bijgewerkt door',
  'FR.Forms.Labels.ShouldAddCommentToQuestion': 'Opmerking vereist voor antwoord N.V.T.',
  'FR.Titles.LastModification': 'LastModification',
  'FR.Titles.Checklist': 'Checklists',
  'FR.Filters.BusinessUnit': 'Bedrijfseenheid',
  'FR.Filters.TaskPlan': 'Taakplan',
  'FR.Actions.CreateNewChecklist': 'Maak Nieuw aan',
  'FR.Actions.Edit': 'Bewerk',
  'FR.Actions.Manage': 'Beheer',
  'FR.Actions.Delete': 'Verwijder',
  'FR.Titles.MOC': 'MOC Verandermanagement',
  'FR.Titles.EditChecklist': 'Edit a Checklist'
};
