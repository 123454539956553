<td>
  {{actionItem.ID}}</td>
<td>{{FunctionalReviewResponseItemPriority[actionItem.Priority]}}</td>
<td>{{actionItem.Action}}</td>
<td>{{actionItem.Comment}}</td>
<td>
  <div class="row text-center">
    <div class="col-md-6">

      <button type="button" class="action-item__action btn btn-primary"
        (click)="editResponseItem(actionItem)">{{'FR.Actions.Edit'|translate}}</button>
    </div>
    <div class="col-md-6">

      <button type="button" class="action-item__action btn btn-danger"
        (click)="deleteResponseItem(actionItem.ID)">{{'FR.Actions.Delete'|translate}}</button>
    </div>
  </div>

</td>

<p-confirmDialog #confirmDialog [key]="'item-'+actionItem.ID">
  <p-footer>
    <button type="button" pButton icon="pi pi-check" [label]="'FR.Dialogs.Actions.Yes'|translate" (click)="confirmDialog.accept()"></button>
    <button type="button" pButton class="ui-button-secondary" icon="pi pi-times" [label]="'No' | translate"
      (click)="confirmDialog.reject()"></button>
  </p-footer>
</p-confirmDialog>