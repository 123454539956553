import { Component, OnDestroy, OnInit } from '@angular/core';
import { Area, BusinessUnit } from 'models';
import { AreaService, BusinessUnitsService } from '#services/api';
import { ToastrService } from '#services/shared/toastr.service';
import { Observable, Subscription } from 'rxjs';
import { FunctionalReviewService } from 'functional-review/services/api/functional-review.service';
import { FunctionalReviewTaskPlan } from 'functional-review/models/functional-review-taskplan';
import { ChecklistService } from 'functional-review/services/api/checklist.service';
import { FunctionalReviewDetails } from 'functional-review/models/functional-review-details';
import { ActivatedRoute, Router } from '@angular/router';
import { share } from 'rxjs/operators';
import { FunctionalReviewResponseGroupedBySectionHelper } from 'functional-review/services/helpers/functional-review-response-grouped-by-section.helper';
import { FunctionalReviewResponseGroupedBySection } from 'functional-review/models/functional-review-response-grouped-by-section';
import { HttpErrorFormat } from 'functional-review/models/http-error-format';
import { FunctionalReviewSharedHelper } from 'functional-review/services/helpers/functional-review-shared.helper';
import { TranslatePipe } from '@ngx-translate/core';
import { FunctionalReviewDTO } from 'functional-review/models/functional-review-dto';

@Component({
  selector: 'app-functional-review',
  templateUrl: './functional-review.component.html',
  styleUrls: ['./functional-review.component.scss'],
  providers: [FunctionalReviewResponseGroupedBySectionHelper, TranslatePipe]
})
export class FunctionalReviewComponent implements OnInit, OnDestroy {

  businessUnits: BusinessUnit[] = [];
  listAssets: Area[] = [];
  listCheckLists: FunctionalReviewTaskPlan[] = [];

  selectedBusinessUnit: BusinessUnit = null;
  selectedAsset: Area = null;
  selectedTaskPlan: FunctionalReviewTaskPlan = null;

  mocId: number;
  private frChecklistRecordId: number;

  activeFunctionalReview: FunctionalReviewDetails = null;

  private _subscription = new Subscription();

  sections$: Observable<FunctionalReviewResponseGroupedBySection[]>;

  constructor(
    private businesUnitSvc: BusinessUnitsService,
    private areaService: AreaService,
    private functionalReviewService: FunctionalReviewService,
    private toastrService: ToastrService,
    private checklistService: ChecklistService,
    private router: Router,
    private route: ActivatedRoute,
    private functionalReviewSharedHelper: FunctionalReviewSharedHelper,
    private responseHelper: FunctionalReviewResponseGroupedBySectionHelper,
    private translatePipe: TranslatePipe
  ) {
    this.sections$ = this.responseHelper.sections$;
  }

  ngOnInit(): void {

    this.mocId = +(sessionStorage.getItem('mocId') || this.route.snapshot.queryParams.mocId);
    this.frChecklistRecordId = +(sessionStorage.getItem('frChecklistRecordId') || this.route.snapshot.queryParams.frChecklistRecordId);

    if (!('businessUnitId' in this.route.snapshot.params)) {
      return;
    }

    this.loadBusinessUnits();

    if (this.route.snapshot.params.areaId) {
      this.loadAssets(+this.route.snapshot.params.businessUnitId);
    }

    if (this.route.snapshot.params.taskPlanId) {
      this.loadTaskPlans(+this.route.snapshot.params.businessUnitId);
    }

  }

  loadBusinessUnits() {
    if (this.selectedBusinessUnit !== null) {
      return;
    }

    const businessUnits$ = this.businesUnitSvc.getUserBUs().pipe(
      share()
    );
    this._subscription.add(
      businessUnits$.subscribe(data => {
        this.businessUnits = data;

        if (this.route.snapshot.params.businessUnitId) {
          this.changeBusinessUnit(data.find(b => b.Id === +this.route.snapshot.params.businessUnitId));
        }
      }, ({ error }: HttpErrorFormat) => {

        const { details, summary } = this.functionalReviewSharedHelper.getWebError({ error });

        this.toastrService.showError(details, summary);

      })
    );
    return businessUnits$;
  }

  changeBusinessUnit(data: BusinessUnit = null) {
    if (data !== null && this.selectedBusinessUnit !== null && data.Id !== this.selectedBusinessUnit.Id) {
      this.activeFunctionalReview = null;
      this.selectedAsset = null;
      this.selectedTaskPlan = null;
    }

    this.selectedBusinessUnit = data;

    this._saveRouteState();
  }

  private _saveRouteState() {
    const params = {
      ...this.route.snapshot.params
    };
    if (this.selectedBusinessUnit !== null) {
      params['businessUnitId'] = this.selectedBusinessUnit?.Id;
    }
    if (this.selectedAsset !== null) {
      params['areaId'] = this.selectedAsset?.Id;
    }
    if (this.selectedTaskPlan !== null) {
      params['taskPlanId'] = this.selectedTaskPlan?.Id;
    }

    this.router.navigate(['/functional-review', params], {
      queryParamsHandling: 'merge',

      preserveFragment: true
    });
  }

  changeAsset(data: Area = null) {
    this.selectedAsset = data;

    this._saveRouteState();
  }

  loadAssets(businessUnitId: number) {
    if (this.selectedAsset !== null && this.selectedAsset.BusinessUnitID === businessUnitId) {
      return;
    }
    this._subscription.add(
      this.areaService
        .getAllByBusinessUnit(businessUnitId)
        .subscribe((data: Area[]) => {
          this.listAssets = data;

          if (this.route.snapshot.params.areaId) {
            this.changeAsset(this.listAssets.find(a => a.Id === +this.route.snapshot.params.areaId));
          }
        })
    );
  }

  loadTaskPlans(businessUnitId: number) {
    if (this.selectedTaskPlan !== null && this.selectedTaskPlan.BusinessUnitId === businessUnitId) {
      return;
    }

    const taskPlans$: Observable<FunctionalReviewTaskPlan[]> =
      this.checklistService.getChecklistByBusinessUnitId(businessUnitId);

    this._subscription.add(
      taskPlans$.subscribe((data) => {
        this.listCheckLists = data;

        if (this.route.snapshot.params.taskPlanId) {
          this.changeTaskPlan(this.listCheckLists.find(a => a.Id === +this.route.snapshot.params.taskPlanId));
        }
      })
    );
  }

  changeTaskPlan(taskPlan: FunctionalReviewTaskPlan = null) {
    this.selectedTaskPlan = taskPlan;

    this.loadFunctionalReview();

    this._saveRouteState();
  }

  startFunctionalReview() {
    const payload: FunctionalReviewDTO = {
      TaskPlanId: this.selectedTaskPlan.Id,
      MocId: this.mocId,
      AreaId: this.selectedAsset.Id,
      FRChecklistRecordId: this.frChecklistRecordId
    };

    const start$: Observable<FunctionalReviewDetails> = this.functionalReviewService.start(payload);

    this._subscription.add(
      start$
        .subscribe((data) => {
          this.activeFunctionalReview = data;

          this.loadSections(data);

        }, ({ error }: HttpErrorFormat) => {
          const { details, summary } = this.functionalReviewSharedHelper.getWebError({ error });

          this.toastrService.showError(details, summary);
        })
    );
  }

  private loadSections(data: FunctionalReviewDetails = null) {
    if (data !== null) {
      this.responseHelper.setConfigurationStorageKey(`fr.responses.${data.ID}`);
    }

    const sections = data === null ? [] : data.Sections;

    this.responseHelper.setSections(sections);
  }

  loadFunctionalReview() {
    if (this.selectedTaskPlan === null || !this.mocId) {
      return;
    }

    const loadFunctionalReview$ = this.functionalReviewService.loadFunctionalReview(
      this.selectedTaskPlan.Id,
      this.mocId,
      this.frChecklistRecordId
    )
      .pipe(
        share()
      );

    this._subscription.add(
      loadFunctionalReview$
        .subscribe((data) => {
          this.activeFunctionalReview = data;

          this.loadSections(data);
        })
    );

    return loadFunctionalReview$;
  }

  completeFunctionalReview() {
    if (!this.selectedBusinessUnit && !this.selectedAsset) {
      this.toastrService.showWarning(
        this.translatePipe.transform('FR.Errors.BuAssetNotSelected')
      );
      return;
    }

    this._subscription.add(
      this.functionalReviewService.completeReview(this.activeFunctionalReview.ID).subscribe(
        (completedReview) => {
          this.activeFunctionalReview.UpdatedDate = completedReview.UpdatedDate;

          this.toastrService.showSuccess(this.translatePipe.transform('FR.Messages.ReviewCompleted'));
        },
        () => {
          this.toastrService.showError(this.translatePipe.transform('FR.Errors.ReviewError'));
        }
      )
    );
  }

  ngOnDestroy() {
    this._subscription.unsubscribe();
  }

}
